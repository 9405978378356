import { QueryClient } from '@tanstack/react-query'
import {
    createRootRouteWithContext,
    Outlet,
    useParams,
} from '@tanstack/react-router'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Footer from '../components/footer'
import { Toaster } from '@broadlume/willow-ui'

const ENV = import.meta.env.VITE_API_ENV
const ORIGIN = import.meta.env.VITE_ORIGIN

export const Route = createRootRouteWithContext<{
    queryClient: QueryClient
}>()({
    component: () => {
        return (
            <div className="flex flex-1 flex-col">
                <CustomHelmet />
                <Outlet />
                <Footer />
                <Toaster />
            </div>
        )
    },
    notFoundComponent() {
        return (
            <div>
                Client Id not found. Please add client id in the url or use the
                url provided during onboarding.
            </div>
        )
    },
})

const CustomHelmet = () => {
    const origin = ['local', 'dev'].includes(ENV)
        ? ORIGIN
        : window.location.origin

    const params = useParams({
        from: '/$clientid',
    })
    const CLIENT_CUSTOM_CSS_URL =
        origin + '/' + params.clientid + '/css/custom-theme.css'

    const url = `@import url(${CLIENT_CUSTOM_CSS_URL})`
    return (
        <HelmetProvider>
            <Helmet>
                <style>{url}</style>
            </Helmet>
        </HelmetProvider>
    )
}
