import { CognitoIdentityProviderClient } from '@aws-sdk/client-cognito-identity-provider'
import config from '../config.json'
import axios from 'axios'
import { CognitoJwtVerifier } from 'aws-jwt-verify'

export const cognitoClient = new CognitoIdentityProviderClient({
    region: config.region,
})

export const verifier = CognitoJwtVerifier.create({
    userPoolId: config.userPoolId,
    clientId: config.clientId,
    tokenUse: 'access',
})

export const axiosInstance = axios.create({
    baseURL: 'https://dev.api.bms.my.broadlume.com', // Set default base URL
    headers: {
        'Content-Type': 'application/json',
        // token: 'f539ea6b01e677d334f6e5711fe4cc08',
        // 'x-api-key': '6q5EVranOvbPsEBt7qwmc66mVV4n11ZzZyK2YfMZ',
    },
})
