import { createFileRoute, redirect } from '@tanstack/react-router'
import { authQueryFn, getTabs } from '../../services/auth'

export const Route = createFileRoute('/$clientid/')({
    beforeLoad: async ({ params, location, context }) => {
        // We will add authentication here later
        // Route users to default broadlume page in case of missing client id.
        console.log('beforeload params.clientid', params, location)

        if (!params?.clientid) {
            throw redirect({
                to: '/404',
            })
        }
        params.clientid = params.clientid.toLowerCase()
        const { queryClient } = context
        const data = await queryClient.fetchQuery({
            queryKey: ['authKeys'],
            queryFn: () => authQueryFn(true),
        })
        if (location.search?.source && location.search.source === 'yardi') {
            throw redirect({
                to: '/$clientid/place-order',
                params,
                search: location.search,
            })
        }
        const tabList = getTabs({ userDetails: data?.userDetails })
        throw redirect({
            to: '/$clientid/' + (tabList?.[0]?.id || 'dashboard'),
            params,
        })
    },
})
