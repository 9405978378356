import { differenceInDays } from 'date-fns'
import { z } from 'zod'

export const emailSchema = z.string({ message: 'Email is required' }).email({
    message: 'Invalid Email address',
})

export const passwordSchema = z
    .string({ message: 'Password is required' })
    .min(8, { message: '8-character minimum length' })
    .max(20, { message: '20-character maximum length' })
    .refine((password) => /[A-Z]/.test(password), {
        message: 'Contains at least 1 uppercase letter',
    })
    .refine((password) => /[a-z]/.test(password), {
        message: 'Contains at least 1 lowercase letter',
    })
    .refine((password) => /[0-9]/.test(password), {
        message: 'Contains at least 1 number',
    })
    .refine((password) => /[!@#$%^&*]/.test(password), {
        message: 'Contains at least 1 special character from the following set',
    })

export const codeSchema = z
    .string()
    .regex(/^[0-9]{6}$/, 'Confirmation Code should be a number of 6 digits')
export const loginSchema = z.object({
    email: emailSchema,
    password: z
        .string({ message: 'Password is required' })
        .min(2, 'Password too short!'),
})
export const resetPasswordSchema = z.object({
    password: passwordSchema,
    code: codeSchema,
    email: emailSchema,
    confirmPassword: z.string({
        message: 'Confirm Password is required',
    }),
})

const startEndDateValidation = z
    .object({
        from: z.date(),
        to: z.date(),
    })
    .refine(({ from, to }) => differenceInDays(to, from) <= 365, {
        message:
            'Date range cannot exceed 365 days. Please select a new date range.',
    })

export const orderAnalysisFormValidation = z.object({
    startEndDate: startEndDateValidation,
    // endDate: z.date(),
    sortBy: z.string(),
    orderBy: z.enum(['asc', 'desc']),
    selection1: z.string(),
    selection2: z.string().optional(),
    selection3: z.string().optional(),
    selection4: z.string().optional(),
    selectLocation: z.array(z.string()),
    selectBuilding: z.array(z.string()),
    selectUnit: z.array(z.string()),
})

export const placeOrderFilterValidation = z.object({
    shipTo: z.string({ message: 'ShipTo is required' }).min(1),
    unitType: z.string({ message: 'UnitType is required' }).min(1),
})

export type PlaceOrderFilterValidationType = z.infer<
    typeof placeOrderFilterValidation
>

export const placeOrderFormValidation = z.object({
    orderedBy: z.string({ message: 'OrderedBy is required' }).min(1),
    email: emailSchema,
    poNo: z.string({ message: 'PO Number is required' }).min(1),
    occupied: z.string({ message: 'Occupied is required' }).min(1),
    moveInDate: z.date().optional(),
    anyTime: z.string().optional(),
    requestedInstallDate: z.date({
        message: 'Install date is required',
    }),
    onsiteContact: z.string().optional(),
    onsiteContactInfo: z.string().optional(),
    streetAddress: z.string().optional(),
    buildingNumber: z.string().optional(),
    unitNumber: z.string({ message: 'Unit number is required' }).min(1),
    generalComment: z.string().optional(),
    items: z.record(z.string(), z.boolean()),
})

export const userAdminSchema = z
    .object({
        displayName: z.string().min(1, { message: 'Display Name is required' }),
        emailAddress: z
            .string()
            .email({ message: 'Invalid email address' })
            .min(1, { message: 'Email address is required' }),
        password: passwordSchema,
        reEnterPassword: passwordSchema,
        selectProperty: z.string().optional().nullable(),
        pageAccessLabelName: z.array(z.string()).optional().nullable(),
    })
    .refine((data) => data.password === data.reEnterPassword, {
        path: ['reEnterPassword'], // This points to the field that should display the error
        message: 'Passwords must match',
    })

export type PlaceOrderFormValidation = z.infer<typeof placeOrderFormValidation>

export const filterSchema = z.object({
    searchField: z.string(),
    searchText: z.string(),
    sortBy: z.string(),
    status: z.string(),
    selectLocation: z.array(z.string()),
    selectBuilding: z.array(z.string()),
    selectUnit: z.array(z.string()),
    startEndDate: z
        .object({
            from: z.date(),
            to: z.date(),
        })
        .refine(({ from, to }) => differenceInDays(to, from) <= 365, {
            message:
                'Date range cannot exceed 365 days. Please select a new date range.',
        }),
    sortDirection: z.enum(['0', '1']).optional(),
    shipTo: z.string().optional(),
    unitType: z.string().optional(),
})

export const invoiceFilterSchema = z.object({
    searchField: z.string().optional(),
    searchText: z.string().optional(),
    sortBy: z.string().optional(),
    status: z.string().optional(),
    selectLocation: z.array(z.string()).optional(),
    selectBuilding: z.array(z.string()).optional(),
    selectUnit: z.array(z.string()).optional(),
    startEndDate: z
        .object({
            from: z.date(),
            to: z.date(),
        })
        .refine(({ from, to }) => differenceInDays(to, from) <= 365, {
            message:
                'Date range cannot exceed 365 days. Please select a new date range.',
        }),
    shipTo: z.string().optional(),
    unitType: z.string().optional(),
})

export type filterSchemaValidation = z.infer<typeof filterSchema>
export type invoiceFilterSchemaValidation = z.infer<typeof invoiceFilterSchema>
