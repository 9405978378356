import { AiOutlineLoading3Quarters } from 'react-icons/ai'
export default function LoadingComponent({
    classNames = '',
}: {
    classNames?: string
}) {
    return (
        <AiOutlineLoading3Quarters
            className={`animate-spin w-10 h-10 ${classNames}`}
        />
    )
}
