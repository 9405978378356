import { format, isAfter, isBefore, isSameDay, parse } from 'date-fns'
import _ from 'lodash'
import { DateRange } from './interfaces/orders.interface'
import {
    GetOrdersOrdFileAPIObject,
    GetPendingOrderAPIObject,
} from './interfaces/place-order.interface'

export const trimTrailingSpacesInArray = (
    array: Array<Record<string, string>>
): unknown => {
    return array.map((obj) => {
        Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'string') {
                obj[key] = obj[key].trimEnd()
            }
        })
        return obj
    })
}

export const readableDate = (date: string) => {
    const newDate = []
    if (date === '00000000' || !date) {
        return null
    }
    for (let i = 0; i < 3; i++) {
        let splittedDate = ''
        if (date.length > 4) {
            splittedDate = date.substring(0, 2)
            date = date.substring(2)
        } else {
            splittedDate = date.substring(0, 4)
            date = date.substring(4)
        }
        newDate.push(splittedDate)
    }

    return newDate.join('/')
}

export const groupByDeep = (collection: Array<unknown>, keys: string[]) => {
    return _.chain(collection)
        .map((item) => _.map(keys, (key) => _.get(item, key)))
        .reduce((result, paths, idx) => {
            const items = _.get(result, paths.join('.'), [])
            _.set(result, paths.join('.'), [...items, collection[idx]])
            return result
        }, {})
        .value()
}

export const generateTransactionID = (): string => {
    const year = new Date().getFullYear().toString().padStart(4, '0')
    const month = (new Date().getMonth() + 1).toString().padStart(2, '0')
    const day = new Date().getDate().toString().padStart(2, '0')
    const hour = new Date().getHours().toString().padStart(2, '0')
    const minute = new Date().getMinutes().toString().padStart(2, '0')
    const second = new Date().getSeconds().toString().padStart(2, '0')
    const random = Math.floor(Math.random() * 100)
        .toString()
        .padStart(2, '0')

    return year.slice(-2) + month + day + hour + minute + second + random
}

export const convertDateYYYYMMDD = (date: Date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}${month}${day}`
}

export function addToRange(
    /** The date to add to the range. */
    date: Date,
    /** The range where to add `date`. */
    initialRange: DateRange | undefined
): DateRange | undefined {
    const { from, to } = initialRange || {}

    let range: DateRange | undefined

    if (!from && !to) {
        // the range is empty, add the date
        range = { from: date, to: date }
    } else if (from && !to) {
        // adding date to an incomplete range
        if (isSameDay(from, date)) {
            // adding a date equal to the start of the range
            range = undefined
        } else if (isBefore(date, from)) {
            // adding a date before the start of the range
            range = { from: date, to: from }
        } else {
            // adding a date after the start of the range
            range = { from, to: date }
        }
    } else if (from && to) {
        // adding date to a complete range
        if (isBefore(date, from)) {
            // adding a date before the start of the range
            range = { from: date, to: to }
        } else if (isAfter(date, from)) {
            // adding a date after the start of the range
            range = { from, to: date }
        } else if (isAfter(date, to)) {
            // adding a date after the end of the range
            range = { from, to: date }
        } else {
            range = { from: date, to: date }
        }
    }

    return range
}

/**
 * Converts a camelCase string to a title case string.
 * @param camelCase - A string in camelCase format.
 * @returns A string converted to title case.
 */
export const camelToTitle = (camelCase: string): string =>
    camelCase
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (match) => match.toUpperCase())
        .trim()

/**
 * Determines the checked state of a checkbox item within a list.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Object} params.item - The checkbox item to evaluate.
 * @param {string} params.item.label - The label of the checkbox item.
 * @param {string} params.item.value - The value of the checkbox item.
 * @param {string[]} params.selectedArr - Array of selected item values.
 * @param {number} params.sourceArrLength - Total number of items in the source array.
 * @returns {boolean | string} - Returns `true` if all items are selected,
 * `false` if none are selected, 'indeterminate' if some are selected,
 * or checks if the specific item is included in the selected array.
 */
export const isCheckboxChecked = ({
    selectedArr = [],
    sourceArrLength,
    item,
}: {
    item: { label: string; value: string }
    selectedArr: string[]
    sourceArrLength: number
}) => {
    if (item.label.toLowerCase() === 'select all') {
        if (selectedArr.length) {
            if (selectedArr.length === sourceArrLength) {
                return true
            } else {
                return 'indeterminate'
            }
        } else {
            return false
        }
    }
    return selectedArr.includes(item.value)
}

export const sortFn = (selector: string) => {
    return function (a, b) {
        if (
            a.id === 'totals' ||
            a.id === '' ||
            b.id === '' ||
            b.id === 'totals'
        )
            return 0
        switch (selector) {
            case 'orderNumber':
            case 'poNumber':
                if (Number(a[selector]) > Number(a[selector])) {
                    return 1
                } else if (Number(a[selector]) < Number(a[selector])) {
                    return -1
                }
                return 0
            case 'date':
            case 'SLS_INVDATE':
                if (new Date(a[selector]) > new Date(b[selector])) {
                    return 1
                } else if (new Date(a[selector]) < new Date(b[selector])) {
                    return -1
                }
                return 0
            default:
                if (a[selector] > b[selector]) {
                    return 1
                } else if (a[selector] < b[selector]) {
                    return -1
                }
                return 0
        }
    }
}

export const allSortFn = ({ sortBy = '', a, b, sortByDirection }) => {
    switch (sortBy) {
        case '':
        case 'totals':
            return 0
        case 'orderNumber':
            return sortByDirection === '1'
                ? Number(a.id) - Number(b.id)
                : Number(b.id) - Number(a.id)
        case 'poNumber':
            return sortByDirection === '1'
                ? Number(a.poNumber) - Number(b.poNumber)
                : Number(b.poNumber) - Number(a.poNumber)
        case 'orderBy':
            if (sortByDirection === '1' && a.orderBy > b.orderBy) {
                return 1
            } else {
                return -1
            }
        case 'customerName':
            if (sortByDirection === '1' && a.customerName > b.customerName) {
                return 1
            } else {
                return -1
            }
        case 'locationCode':
            if (sortByDirection === '1' && a.orderBy > b.orderBy) {
                return 1
            } else {
                return -1
            }
        case 'buildingUnit':
            if (sortByDirection === '1' && a.orderBy > b.orderBy) {
                return 1
            } else {
                return -1
            }
        case 'installDate':
            if (sortByDirection === '1' && a.orderBy > b.orderBy) {
                return 1
            } else {
                return -1
            }
        case 'orderDate':
            if (sortByDirection === '1' && a.orderBy > b.orderBy) {
                return 1
            } else {
                return -1
            }

        default:
            return sortByDirection === '1'
                ? Number(a.id) - Number(b.id)
                : Number(b.id) - Number(a.id)
    }
}

export const getPendingOrderDataTableRow = (
    order: GetPendingOrderAPIObject
) => {
    return {
        id: order.PODMO_TRANSACTION,
        poNumber: order.PODMO_CUST_PO,
        // date: readableDate(order.PODMO_DATE),
        date: format(
            parse(order.PODMO_DATE, 'yyyyMMdd', new Date()),
            'MM/dd/yyyy'
        ),
        installDate: format(
            parse(order.PONOTE_DATE, 'yyyyMMdd', new Date()),
            'MM/dd/yyyy'
        ),
        // installDate: readableDate(order.PONOTE_DATE),
        customerName: order.CADD_NAME,
        addresses: `${order.CADD_ADDR1}
        #${order.PODMO_UNIT}
        ${order.PODMO_INSTALL_ADDR}`,
        orderBy: order.PODMO_WEB_USER,
        status: 'Pending',
        action: '',
    }
}

export const getOrderDataTableRow = (order: GetOrdersOrdFileAPIObject) => {
    return {
        id: order.DMO_ORDNO,
        date: readableDate(order.DMH_DATE),
        customerName: order.CADD_NAME,
        addresses: order.C_ADDR1 + order.DMH_SHADDR1,
        orderBy: order.DMH_ORDEREDBY,
        action: '',
        orderNumber: order.DMO_ORDNO,
        poNumber: order.DMH_CUSTPO,
        invoiceDate: readableDate(order.DMH_DATE),
        dueDate: readableDate(order.DMH_INSTALLDATE),
    }
}
