import { getRouteApi, useNavigate } from '@tanstack/react-router'
import logo from '../Logo.png'

const router = getRouteApi('/$clientid')
export const OnboardingContainer = ({ children }: React.PropsWithChildren) => {
    const navigate = useNavigate({ from: '/' })
    const params = router.useParams()

    return (
        <div className="flex-1 relative flex flex-col items-center justify-center">
            <div className="absolute top-0 left-0 md:block hidden">
                <img
                    src="/svg/loginUpImg.svg"
                    alt=""
                    className="md:w-[17vw] w-[20vw]"
                />
            </div>
            <div className="mb-0 mt-8">
                <div
                    className="flex justify-center md:py-2 py-6 cursor-pointer"
                    onClick={async () => {
                        await navigate({
                            to: '/$clientid/login',
                            params: params,
                        })
                    }}
                >
                    <img
                        data-testid="broadlume-logo"
                        src={logo}
                        alt=""
                        className="w-[25vw] cursor-pointer"
                    />
                </div>
            </div>
            {children}
            <div className="absolute bottom-0 right-0 md:block hidden">
                <img
                    src="/svg/loginUpImg.svg"
                    alt=""
                    style={{
                        transform: 'scale(-1, -1)',
                    }}
                    className="md:w-[17vw] w-[20vw]"
                />
            </div>
        </div>
    )
}
