import {
    Avatar,
    AvatarFallback,
    AvatarImage,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@broadlume/willow-ui'
import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { PrimaryButton } from './primary-button'
import { handleTabShow } from '../services/auth'
import { useAuthHook } from '../utils/hooks/order-analysis.hook'
const router = getRouteApi('/$clientid/_app')
const Header = ({ isYardiSource }: { isYardiSource?: boolean }) => {
    const navigate = useNavigate({ from: '/' })
    const params = router.useParams()
    const { data: userData } = useAuthHook()
    const handleLogout = () => {
        sessionStorage.clear()
        navigate({ to: '/$clientid/login', params: params })
    }
    return (
        <div className="bg-willow-ash text-willow-wool-light top-0">
            <div className="flex flex-row justify-between align-middle pl-12 pr-12 pt-4 pb-4">
                <div className="flex justify-center">
                    <img
                        src="/broadlume-logo.png"
                        alt="broadlume_logo"
                        width={249}
                        height={28}
                        onClick={async () => {
                            if (!isYardiSource) {
                                await navigate({
                                    to: '/$clientid',
                                    params: params,
                                })
                                location.reload()
                            }
                        }}
                    />
                </div>

                <div className="flex flex-row gap-2">
                    {!isYardiSource &&
                    handleTabShow({
                        userDetails: userData?.userDetails,
                        module: 'write:orders',
                    }) ? (
                        <PrimaryButton
                            title="+ Place Order"
                            onClick={() =>
                                navigate({ to: '/$clientid/place-order' })
                            }
                        />
                    ) : null}

                    <DropdownMenu>
                        <DropdownMenuTrigger className="flex flex-row items-center gap-2">
                            <Avatar size={33}>
                                <AvatarImage
                                    alt="person"
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsP-p8Y55bpJCDFSNhLuacAsxePs7ioSpL9A&s"
                                />
                                <AvatarFallback>SH</AvatarFallback>
                            </Avatar>
                            <img src="/down-arrow-icon.png" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem>Profile</DropdownMenuItem>
                            <DropdownMenuItem>Billing</DropdownMenuItem>
                            <DropdownMenuItem>Team</DropdownMenuItem>
                            <DropdownMenuItem>Subscription</DropdownMenuItem>
                            <DropdownMenuItem onClick={handleLogout}>
                                Logout
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </div>
        </div>
    )
}

export default Header
