import { createFileRoute } from '@tanstack/react-router'
import z from 'zod'

import PlaceOrder from '../../../pages/place-order'

export const Route = createFileRoute('/$clientid/_app/place-order')({
    component: PlaceOrder,
    validateSearch: (search) =>
        z
            .object({
                source: z.string().trim().optional(),
            })
            .optional()
            .parse(search),
})
