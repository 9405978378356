import { convertDateYYYYMMDD, trimTrailingSpacesInArray } from '../utils'
import {
    GetBuildingFromInvoiceApiResponse,
    GetInvoiceSearchAPIResponse,
    GetOrderHeaderAPIResponse,
    GetOrderLinesAPIResponse,
    GetOrdersOrdFileAPIResponse,
    GetPendingOrdersAPIResponse,
    GetShipToCodeApiResponse,
    OrderType,
} from '../utils/interfaces/place-order.interface'
import { axiosInstance } from './api'
import { authQueryFn } from './auth'

export async function getOrders(payload: {
    startDate: string
    endDate: string
    type?: OrderType
}): Promise<GetPendingOrdersAPIResponse> {
    const { userDetails } = authQueryFn()
    const queryParam = new URLSearchParams({
        function: 'getpendingorders',
        alias: userDetails.api_config,
        branch: userDetails.Branch,
        company: userDetails.Company,
        properties: userDetails.Properties,
        startdate: payload.startDate,
        enddate: payload.endDate,
    })
    return axiosInstance('/api/getpendingorders', {
        params: queryParam,
    }).then((response) => response.data as GetPendingOrdersAPIResponse)
}

export async function getSalesAnalysis({
    startEndDate,
    ...params
}: {
    startEndDate: { from: Date; to: Date }
    buildings?: string
    units?: string
}) {
    const { userDetails } = authQueryFn()
    return axiosInstance.get('/api/getsalesanalysis', {
        params: {
            function: 'getsalesanalysis',
            alias: userDetails.api_config,
            // branch: '1/*%5C2/*%5C3',
            branch: userDetails.Branches,
            company: userDetails.Company,
            properties: userDetails.Properties,
            startdate: convertDateYYYYMMDD(startEndDate.from),
            enddate: convertDateYYYYMMDD(startEndDate.to),
            ...(params ?? {}),
        },
    })
}

export async function getShipToCodes() {
    const { userDetails } = authQueryFn()
    return axiosInstance
        .get('/api/testdebug', {
            params: {
                function: 'shiptocodes',
                branch: userDetails.Branches,
                company: userDetails.Company,
                properties: userDetails.Properties,
                alias: userDetails.api_config,
            },
        })
        .then((resp) => resp.data as GetShipToCodeApiResponse)
}

export async function getBuildingFromInvoice() {
    const { userDetails } = authQueryFn()
    return axiosInstance
        .get('/api/bldgfrominvoice', {
            params: {
                function: 'bldgfrominvoice',
                branch: userDetails.Branches,
                company: userDetails.Company,
                properties: userDetails.Properties,
                alias: userDetails.api_config,
            },
        })
        .then((resp) => resp.data as GetBuildingFromInvoiceApiResponse)
}

// /api/v2/testdebug?function=getordersordfile
// &alias=sales_demo
// &branch=1/*%5C2/*%5C3
// &company=99
// &properties=JWOODS%2c0000/*%5CPATRICK%2c0134/*%5CPATRICK%2c1728/*%5CPATRICK%2c0000/*%5CALLS6108%2c0000/*%5COTIL2034%2c0000/*%5CPRITCH02%2c0000/*%5CPRITCHET%2c1743/*%5CBIGDADDY%2c0000/*%5CMANDALAY%2c0000/*%5CSURFACES%2c0000/*%5C
// &startdate=20220101
// &enddate=20230101

// /api/v2/testdebug?function=getordersordfile
// &alias=sales_demo
// &branch=1/*%5C2/*%5C3
// &company=99
// &properties=MANDALAY,0000
// &buildings=3960//**%5C%5C3965
// &units=103//**%5C%5C11124
// &startdate=20220101
// &enddate=20230101

export async function getOrdersOrdFile(payload: {
    startDate: Date
    endDate: Date
    status: string
    buildings?: string[]
    units?: string[]
    properties?: string[]
}): Promise<GetOrdersOrdFileAPIResponse | GetPendingOrdersAPIResponse> {
    const { userDetails } = authQueryFn()
    const endpoint =
        payload.status === 'pending' ? 'getpendingorders' : 'getordersordfile'
    return axiosInstance
        .get(`/api/${endpoint}`, {
            params: {
                function: endpoint,
                branch: userDetails.Branches,
                company: userDetails.Company,
                // For properties, the separator remains /*\
                properties:
                    payload?.properties?.join('/*\\') ?? userDetails.Properties,
                // buildings: separator /**\\  3960/**\\3965
                buildings: payload?.buildings?.join('//**\\\\') || '',
                // units: separator /**\\ 103//**\\11124
                units: payload?.units?.join('//**\\\\') || '',
                alias: userDetails.api_config,
                startdate: convertDateYYYYMMDD(payload.startDate),
                enddate: convertDateYYYYMMDD(payload.endDate),
            },
        })
        .then((resp) =>
            payload.status === 'pending'
                ? (trimTrailingSpacesInArray(
                      resp.data
                  ) as GetPendingOrdersAPIResponse)
                : (trimTrailingSpacesInArray(
                      resp.data
                  ) as GetOrdersOrdFileAPIResponse)
        )
}
// Pending Orders

// /api/v2/testdebug?function=getpendingorders
// &alias=sales_demo
// &branch=1/*%5C2/*%5C3
// &company=99
// &properties=MANDALAY,0000
// &buildings=3960
// &units=645//**%5C%5C23
// &startdate=20220101
// &enddate=20230101

// /api/v2/testdebug?function=getinvoicessearch
// &alias=sales_demo
// &branch=1/*%5C2/*%5C3
// &company=99
// &properties=JWOODS%2c0000/*%5CPATRICK%2c0134/*%5CPATRICK%2c1728/*%5CPATRICK%2c0000/*%5CALLS6108%2c0000/*%5COTIL2034%2c0000/*%5CPRITCH02%2c0000/*%5CPRITCHET%2c1743/*%5CBIGDADDY%2c0000/*%5CMANDALAY%2c0000/*%5CSURFACES%2c0000/*%5C
// &searchin=DMH_SHADDR1
// &searchtype=equals
// &searchfor=
// &searchforno=
// &startdate=20231202
// &enddate=20241202
// &proptypes=MANDALAY%2f*%5c0000%2f*%5c1BEDROOM+SPECIAL%2f*%5c1%2f%2f**%5c%5cMANDALAY%2f*%5c0000%2f*%5cB4%2f*%5c1%2f%2f**%5c%5c
// MANDALAY/*\0000/*\1BEDROOM+SPECIAL/*\1
// //**\\
// MANDALAY/*\0000/*\B4/*\1//**\\

// B4/*\1
// PDMO_PROPTYPE/*\PDMO_WHSE

export async function getInvoiceSearch(payload: {
    startDate: Date
    endDate: Date
    status: string
    unitTypes?: string[] // ["B4,1"]
    shipTos?: string[] // These are MANDALAY,0000, customer and shipTo Combined. ["MANDALAY,0000"]
}): Promise<GetInvoiceSearchAPIResponse> {
    const { userDetails } = authQueryFn()
    const finalPropTypes: string[] = []

    if (payload?.shipTos) {
        // payload.properties
        payload?.shipTos?.map((eachLocation) => {
            payload.unitTypes?.map((eachProperty) => {
                finalPropTypes.push(
                    eachLocation.replace(',', '/*\\') +
                        '/*\\' +
                        eachProperty.replace(',', '/*\\').replace(' ', '')
                )
            })
        })
    }

    console.log('finalPropTypes', finalPropTypes)

    return axiosInstance
        .get('/api/getinvoicessearch', {
            params: {
                function: 'getinvoicessearch',
                branch: userDetails.Branches,
                company: userDetails.Company,
                // For properties, the separator remains /*\
                properties: userDetails.Properties,
                proptypes: finalPropTypes.length
                    ? finalPropTypes?.join('//**\\\\') + '//**\\\\'
                    : '',
                // buildings: separator /**\\  3960/**\\3965
                buildings: payload?.buildings?.join('//**\\\\') || '',
                // units: separator /**\\ 103//**\\11124
                units: payload?.units?.join('//**\\\\') || '',
                searchin: 'DMH_SHADDR1',
                searchtype: 'equals',
                searchfor: '',
                searchforno: '',
                alias: userDetails.api_config,
                startdate: convertDateYYYYMMDD(payload.startDate),
                enddate: convertDateYYYYMMDD(payload.endDate),
            },
        })
        .then(
            (resp) =>
                trimTrailingSpacesInArray(
                    resp.data
                ) as GetInvoiceSearchAPIResponse
        )
}

export async function getOrderHeader(
    order: string
): Promise<GetOrderHeaderAPIResponse> {
    const { userDetails } = authQueryFn()
    return axiosInstance
        .get('/api/order', {
            params: {
                function: 'getorderheader',
                branch: userDetails.Branch,
                company: userDetails.Company,
                properties: userDetails.Properties,
                alias: userDetails.api_config,
                order,
            },
        })
        .then(
            (resp) =>
                trimTrailingSpacesInArray(
                    resp.data
                ) as GetOrderHeaderAPIResponse
        )
}

export async function getOrderLines(
    order: string
): Promise<GetOrderLinesAPIResponse> {
    const { userDetails } = authQueryFn()
    return axiosInstance
        .get('/api/getorderlines', {
            params: {
                function: 'getorderlines',
                branch: userDetails.Branch,
                company: userDetails.Company,
                properties: userDetails.Properties,
                alias: userDetails.api_config,
                order,
            },
        })
        .then(
            (resp) =>
                trimTrailingSpacesInArray(resp.data) as GetOrderLinesAPIResponse
        )
}
