import axios from 'axios'

import { UserDetails } from '../utils/interfaces/user.interface'
import { axiosInstance, verifier } from './api'
import { PropertyRegex } from '../utils/constants'

export const signIn = async ({
    username,
    password,
    clientId,
}: {
    username: string
    password: string
    clientId: string
}) => {
    try {
        const response = await axios.post(
            'https://dev.api.bms.my.broadlume.com/login',
            {
                email: username,
                password: password,
                module: clientId,
            }
        )

        await verifier.hydrate()

        const payload = await verifier.verify(response.data.tokens.AccessToken!)

        if (response.data.tokens) {
            sessionStorage.setItem(
                'idToken',
                response.data.tokens.IdToken || ''
            )
            sessionStorage.setItem(
                'accessToken',
                response.data.tokens.AccessToken || ''
            )
            sessionStorage.setItem(
                'refreshToken',
                response.data.tokens.RefreshToken || ''
            )
            const metaData = JSON.parse(
                payload['custom:metadata']
            ) as UserDetails
            /**
             * 1. Check if space is there or not in property
             * 2. replace || and /*\ separators with /*\ and comma(,) respectively
             */
            metaData.Properties = metaData.Properties.replace(' ', '')
            if (PropertyRegex.test(metaData.Properties)) {
                // true -> || is separator between items and /*\ is separator between item label and value.
                metaData.Properties = metaData.Properties.split('||')
                    .map((item) => item.replace(/\/\*\\/g, ','))
                    .join('/*\\')
            }
            metaData.Branches = metaData.Branches.replace(/\|\|$/g, '')
            metaData.Branches = metaData.Branches.replace(/\|\|/g, '/*\\')
            sessionStorage.setItem('userDetails', JSON.stringify(metaData))
            axiosInstance.defaults.headers['Authorization'] =
                'Bearer ' + response.data.tokens.AccessToken
            return response
        }
    } catch (error) {
        console.error('Error signing in: ', error)
        throw error
    }
}

export const authQueryFn = (updateAxios?: boolean) => {
    const accessToken = sessionStorage.getItem('accessToken')
    const idToken = sessionStorage.getItem('idToken')
    const refreshToken = sessionStorage.getItem('refreshToken')
    const userDetailsString = sessionStorage.getItem('userDetails')!
    const userDetails = JSON.parse(userDetailsString) as UserDetails
    if (updateAxios && userDetails?.api_config) {
        axiosInstance.defaults.headers['Authorization'] =
            'Bearer ' + accessToken
    }
    return { accessToken, idToken, refreshToken, userDetails }
}

export const handleTabShow = ({
    userDetails,
    module,
}: {
    userDetails?: UserDetails
    module: string
}) => {
    return userDetails?.access?.includes(module)
}

export const getTabs = ({ userDetails }: { userDetails?: UserDetails }) => {
    if (!userDetails) {
        return []
    }
    const allTabs = [
        {
            id: 'dashboard',
            access: 'read:dashboard',
            img: '/svg/dashboard.svg',
            label: 'DASHBOARD',
            isTab: true,
            isActive: handleTabShow({ userDetails, module: 'read:dashboard' }),
        },
        {
            id: 'orders',
            access: 'read:orders',
            isTab: true,
            isActive: handleTabShow({ userDetails, module: 'read:orders' }),
            img: '/svg/cart.svg',
            label: 'ORDERS',
        },
        {
            id: 'invoices',
            access: 'invoices',
            isTab: true,
            isActive: handleTabShow({ userDetails, module: 'invoices' }),
            img: '/svg/file.svg',
            label: 'INVOICES',
        },
        {
            id: 'order-analysis',
            access: 'read:sales-analysis',
            isTab: true,
            isActive: handleTabShow({
                userDetails,
                module: 'read:sales-analysis',
            }),
            img: '/svg/analysis.svg',
            label: 'ORDER ANALYSIS',
        },
        {
            id: 'user-admin',
            access: 'user:admin',
            isTab: true,
            isActive: handleTabShow({ userDetails, module: 'user:admin' }),
            img: '/svg/users.svg',
            label: 'USER ADMIN',
        },
        {
            id: 'theme-changer',
            access: 'user:admin',
            isTab: true,
            isActive: handleTabShow({ userDetails, module: 'user:admin' }),
            img: '/svg/theme-changer.svg',
            label: 'THEME CHANGER',
        },
        {
            id: 'place-order',
            access: 'write:orders',
            isTab: false,
            isActive: handleTabShow({ userDetails, module: 'write:orders' }),
            img: '',
            label: 'Place Order',
        },
    ]

    return allTabs.filter((tab) => tab.isActive)
}
