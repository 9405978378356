import {
    GetLaborAPIResponse,
    GetMaterialAPIResponse,
    GetCustomerAPIResopnse,
    GetPOrdFileAPIResopnse,
} from '../utils/interfaces/place-order.interface'
import { trimTrailingSpacesInArray } from '../utils'
import { authQueryFn } from './auth'
import { axiosInstance } from './api'

export async function getCustomer(): Promise<GetCustomerAPIResopnse> {
    const { userDetails } = authQueryFn()
    const queryParam = new URLSearchParams({
        function: 'get_customer',
        alias: userDetails.api_config,
        branch: userDetails.Branch,
        company: userDetails.Company,
        properties: userDetails.Properties,
    })
    return axiosInstance
        .get('/api/get_customer', {
            params: queryParam,
        })
        .then((resp) => resp.data)
}

export async function getPordFile(): Promise<GetPOrdFileAPIResopnse> {
    const { userDetails } = authQueryFn()
    const queryParam = new URLSearchParams({
        function: 'get_pordfile',
        alias: userDetails.api_config,
        branch: userDetails.Branch,
        company: userDetails.Company,
        properties: userDetails.Properties,
    })

    return axiosInstance
        .get('/api/get_pordfile', {
            params: queryParam,
        })
        .then((resp) => resp.data)
}

export async function getLabor({
    property,
    customer,
    shipTo,
}: {
    property: string
    customer: string
    shipTo: string
}): Promise<GetLaborAPIResponse> {
    const { userDetails } = authQueryFn()
    const queryParam = new URLSearchParams({
        function: 'get_labor',
        alias: userDetails.api_config,
        property,
        customer,
        shipTo,
        branch: userDetails.Branch,
        company: userDetails.Company,
    })

    return axiosInstance
        .get('/api/get_labor', {
            params: queryParam,
        })
        .then(
            (resp) =>
                trimTrailingSpacesInArray(resp.data) as GetLaborAPIResponse
        )
}

export async function getMaterial({
    property,
    customer,
    shipTo,
}: {
    property: string
    customer: string
    shipTo: string
}): Promise<GetMaterialAPIResponse> {
    const { userDetails } = authQueryFn()
    const queryParam = new URLSearchParams({
        function: 'get_material',
        alias: userDetails.api_config,
        property,
        customer,
        shipTo,
        branch: userDetails.Branch,
        company: userDetails.Company,
    })

    return axiosInstance
        .get('/api/get_material', {
            params: queryParam,
        })
        .then(
            (resp) =>
                trimTrailingSpacesInArray(resp.data) as GetMaterialAPIResponse
        )
}

export async function createOrder(
    property: string,
    customer: string,
    shipTo: string,
    transaction: string,
    header: string,
    lines: string,
    notes: string,
    comments: string
): Promise<Record<string, string>> {
    const { userDetails } = authQueryFn()
    const queryParam = new URLSearchParams({
        alias: userDetails.api_config,
        branch: userDetails.Branch,
        company: userDetails.Company,
        properties: userDetails.Properties,
        property,
        customer,
        shipTo,
        transaction,
        header,
        lines,
        notes,
        comments,
    })
    return axiosInstance
        .get('/api/create_order', {
            params: queryParam,
        })
        .then((resp) => resp.data)
}

export const saveYardiFile = async () => {
    // TODO: Update this blob to create a dynamic file
    const cXMLBlob = new Blob(
        [
            `<cXML payloadID="2022-03-15 15:06:31.910.123456.1363666.12495.voyager.www.yardimarketplace.com" xml:lang="en_US" timestamp="2022-03-15 15:06:31.910">
    <Header>
        <From>
            <Credential domain="NetworkID">
                <Identity>Yardi123</Identity>
            </Credential>
        </From>
        <To>
            <Credential domain="NetworkID">
                <Identity>Supplier ID</Identity>
            </Credential>
        </To>
        <Sender>
            <Credential domain="NetworkID">
                <Identity>2</Identity>
                <SharedSecret>b800ab29-3c6a-42a9-9e1d-2b0b81ac30ae</SharedSecret>
            </Credential>
            <UserAgent>PROCUREplus Vista P2P</UserAgent>
        </Sender>
    </Header>
    <Request>
        <PunchOutSetupRequest operation="create">
            <BuyerCookie>1254F828D212345678904E1415B4B8AC</BuyerCookie>
            <Extrinsic name="UserID">d94af6ce-5cea-43e8-9c01-d41788a4e5f6</Extrinsic>
            <Extrinsic name="CompAlias">salesdemo</Extrinsic>
            <Extrinsic name="UserName">MANDALAY</Extrinsic>
            <Extrinsic name="BuildingId">012536</Extrinsic>
            <BrowserFormPost>
                <URL>https://www.yardimarketplace.com/voyager/PunchOutAction.do?doAction=orderSubmit&supplierID=435751&atsvieej=undefined</URL>
            </BrowserFormPost>
            <SupplierSetup>
                <URL>SupplierURLtoSendSetupRequest</URL>
            </SupplierSetup>
            <ShipTo>
                <Address addressID="Yardi address ID or supplier-assigned property account">
                    <Name xml:lang="en">AddressName</Name>
                    <PostalAddress>
                        <DeliverTo>AddressName</DeliverTo>
                        <Street>12345 Street Dr</Street>
                        <City>Austin</City>
                        <State>TX</State>
                        <PostalCode>78759-6415</PostalCode>
                        <Country isoCountryCode="US">USA</Country>
                    </PostalAddress>
                </Address>
            </ShipTo>
        </PunchOutSetupRequest>
    </Request>
</cXML>`,
        ],
        {
            type: 'application/xml',
        }
    )

    const formData = new FormData()
    formData.append('file', cXMLBlob, 'yardicXMLFile')

    // TODO: Dynamic Token
    const result = await fetch(
        `https://dev.yardi.bms.my.broadlume.com/Yardi/SaveYardiFile?token=BtqiZkjo3EjqVp2wF0zZRozn8Vh31AbgAA==`,
        {
            body: formData,
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )

    return await result.json()
}

export const createOrderHeader = (payload: {
    poNo: string
    occupied: string
    email: string
    buildingNumber: string
    unitNumber: string
    streetAddress: string
    onsiteContact: string
}) => {
    const { userDetails } = authQueryFn()
    const separator = `/*\\`
    const headerNodeList: string[] = [userDetails.Customer]
    Object.entries(payload).forEach(([, value]) => {
        if (value.length > 0) {
            headerNodeList.push(value)
        }
    })
    headerNodeList.push(userDetails.Branch)
    return headerNodeList.join(separator)
}
