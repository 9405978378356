import { useQuery } from '@tanstack/react-query'
import { getShipToCodes } from '../../services/orders'
import { useAuthHook } from './order-analysis.hook'
import { useGetBuildingFromInvoice } from './building-from-invoice.hook'

export const useShipToCodes = ({
    callBackFn = () => {},
}: {
    callBackFn: (data: {
        allBuildings: {
            label: string
            value: string
        }[]

        allUnits: {
            label: string
            value: string
        }[]

        allLocations: {
            label: string
            value: string
        }[]
    }) => void
}) => {
    const { data: userData } = useAuthHook()

    const {
        data: buildingFromInvoice,
        isLoading: isBuildingFromInvoiceLoading,
    } = useGetBuildingFromInvoice()

    const shipToCodesData = useQuery({
        queryKey: ['getShipToCodes', userData],
        staleTime: 180000,
        enabled: Boolean(buildingFromInvoice),
        queryFn: async () => {
            const resp = await getShipToCodes()
            // "SURFACES - SURFACES PROPERTY MANAGEMENT (1000 MONTREAL STREET)"
            /**
         * {
                "CADD_NAME": "SURFACES PROPERTY MANAGEMENT",
                "CADD_ADDRCD": "0000",
                "CADD_ADDR1": "1000 MONTREAL STREET",
                "CADD_CUST": "SURFACES",
                "CADD_COID": "99"
            }
         */

            const result = resp.filter((item) =>
                buildingFromInvoice?.buildings.some(
                    (building) => building['DMH_CUSTID'] === item.CADD_CUST
                )
            )
            const allLocations = [
                { label: 'Select All', value: 'all' },
                ...result.map((item) => ({
                    label: `${item.CADD_CUST} - ${item.CADD_NAME} (${item.CADD_ADDR1})`,
                    value: `${item.CADD_CUST},${item.CADD_ADDRCD}`,
                })),
            ]
            callBackFn({
                allBuildings: buildingFromInvoice?.buildings,
                allUnits: buildingFromInvoice?.units,
                allLocations: allLocations,
            })
            return {
                allBuildings: buildingFromInvoice?.buildings,
                allUnits: buildingFromInvoice?.units,
                allLocations: allLocations,
            }
        },
    })

    return { ...shipToCodesData, isBuildingFromInvoiceLoading }
}
