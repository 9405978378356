import { Button, ButtonProps } from '@broadlume/willow-ui'
import LoadingComponent from './LoadingComponent'

const PrimaryButton: React.FC<
    ButtonProps &
        React.RefAttributes<HTMLButtonElement> & { isLoading?: boolean }
> = ({ title, isLoading = false, children, ...rest }) => (
    <Button
        className={`custom-primary-button custom-primary-font text-xs border border-willow-ash ${rest.disabled ? 'opacity-60' : ''}`}
        {...rest}
    >
        {isLoading && <LoadingComponent classNames="w-4 h-4 mr-2" />}
        {title ?? children}
    </Button>
)

export { PrimaryButton }
