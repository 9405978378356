import {
    Card,
    CardContent,
    CardFooter,
    DatePicker,
    Label,
    Separator,
    toast,
} from '@broadlume/willow-ui'
import { useForm } from '@tanstack/react-form'
import { useQuery } from '@tanstack/react-query'
import { getRouteApi } from '@tanstack/react-router'
import { useMemo, useState } from 'react'
import { useAuthHook } from '../utils/hooks/order-analysis.hook'

import {
    AccordianMaterialList,
    GetCustomerAPIObject,
    GetCustomerAPIResopnse,
    GetLaborAPIResponse,
    GetPOrdAPIObject,
    GetPOrdFileAPIResopnse,
    ShipToUnitTypeDropDown,
} from '../utils/interfaces/place-order.interface'

import {
    convertDateYYYYMMDD,
    generateTransactionID,
    groupByDeep,
} from '../utils'
import {
    placeOrderFilterValidation,
    placeOrderFormValidation,
} from '../utils/validators/schemas'

import { InputField } from '../components/inputField'
import { ITreeNode, OrderItems } from '../components/place-order/order-items'
import { PrimaryButton } from '../components/primary-button'
import { SecondaryButton } from '../components/secondary-button'

import { zodValidator } from '@tanstack/zod-form-adapter'
import LoadingComponent from '../components/LoadingComponent'
import {
    createOrder,
    createOrderHeader,
    getCustomer,
    getLabor,
    getMaterial,
    getPordFile,
    saveYardiFile,
} from '../services/placeOrder'
import { SelectWrapper } from './order-analysis'

const today = new Date()
today.setDate(today.getDate() - 1)

const route = getRouteApi('/$clientid/_app/place-order')
const PlaceOrder = () => {
    const { data: userData } = useAuthHook()
    const queryParams = route.useSearch()
    let yardiSource = false

    if (queryParams && queryParams.source) {
        yardiSource = queryParams.source === 'yardi'
    }

    const [laborMaterialFetchParams, setLaborMaterialFetchParams] = useState<{
        customer: string
        property: string
        shipTo: string
    }>({
        customer: '',
        property: '',
        shipTo: '',
    })
    const [customer, setCustomer] = useState<GetCustomerAPIObject>()
    const [property, setProperty] = useState<GetPOrdAPIObject>()

    const { data: customerData, isLoading: isCustomerLoading } =
        useQuery<GetCustomerAPIResopnse>({
            queryKey: ['getCustomer', userData],
            queryFn: getCustomer,
            staleTime: Infinity,
        })

    const { data: pordFileData, isLoading: isPordFileLoading } =
        useQuery<GetPOrdFileAPIResopnse>({
            queryKey: ['getPordFile', userData],
            queryFn: getPordFile,
            staleTime: Infinity,
        })

    // get ship to and unittype data form
    const findingForm = useForm({
        onSubmit: async ({ value }) => {
            const customer = customerData?.find(
                (customer) => customer.C_CID === value.shipTo
            )

            const trimmedUnitType = value.unitType.split('___')[1]
            const property = pordFileData?.find(
                (property) => property.PDMO_PROPTYPE === trimmedUnitType
            )

            if (property) {
                setProperty(property)
            }
            if (customer) {
                setCustomer(customer)
            }
            setLaborMaterialFetchParams({
                customer: findingForm?.state?.values?.shipTo ?? '',
                property:
                    findingForm.state.values.unitType.split('___')[1] ?? '',
                shipTo: findingForm.state.values.unitType.split('___')[0] ?? '',
            })
        },
        validators: {
            onChange: placeOrderFilterValidation,
        },
        validatorAdapter: zodValidator(),
        defaultValues: {
            shipTo: '',
            unitType: '',
        },
    })

    const shipToField = findingForm.useField({
        name: 'shipTo',
    })

    const unitTypeField = findingForm.useField({
        name: 'unitType',
    })

    const [orderItemsRequired, setOrderItemsRequired] = useState(false)

    const form = useForm({
        onSubmit: async ({ value }) => {
            console.log(value)

            const shipTo = findingForm.getFieldValue('shipTo')
            const unitType = findingForm.getFieldValue('unitType')
            const unitTypeSplit = unitType.split('___')

            const transaction = generateTransactionID()
            const header = createOrderHeader({
                poNo: value.poNo,
                occupied: value.occupied,
                email: value.email,
                buildingNumber: value.buildingNumber,
                unitNumber: value.unitNumber,
                streetAddress: value.streetAddress,
                onsiteContact: value.onsiteContact,
            })
            if (Object.keys(value.items).length === 0) {
                setOrderItemsRequired(true)
                return
            } else {
                setOrderItemsRequired(false)
            }
            // set lines andd comments
            const items = Object.keys(value.items)
            const lines: string[] = []
            const comments: string[] = []
            const itemSeparator = '/*\\'
            const commentSeparator = '//**\\'
            items.map((item) => {
                const [val1, val2] = item.split('___')
                if (!lines.includes(val1)) {
                    lines.push(val1)
                } else {
                    // create object with key name and pass array string with value.
                    comments.push(val1 + itemSeparator + val2)
                }
            })

            // make notes
            const notes: string[] = []

            // if moveInDate is there
            if (value.moveInDate) {
                const subNote: string[] = [
                    '0',
                    'M',
                    convertDateYYYYMMDD(value.moveInDate),
                    'MOVE IN DATE',
                ]
                notes.push(subNote.join(itemSeparator))
            }

            // if requestedInstallDate is there
            if (value.requestedInstallDate) {
                const subNote: string[] = [
                    '1',
                    'I',
                    convertDateYYYYMMDD(value.requestedInstallDate),
                ]
                if (value.onsiteContactInfo) {
                    subNote.push(value.onsiteContactInfo.toUpperCase())
                }
                // if (value.anyTime !== 'anyTime') {
                //     if (value.anyTime === 'am'){
                //         subNote.push('+')
                //     }

                //     if (value.anyTime === 'pm'){
                //         subNote.push('-')
                //     }
                // }
                notes.push(subNote.join(itemSeparator))
            }

            // if generalComment is there
            if (value.generalComment) {
                const subNote: string[] = [
                    '5',
                    'G',
                    convertDateYYYYMMDD(new Date()),
                    value.generalComment.toUpperCase(),
                ]
                notes.push(subNote.join(itemSeparator))
            }

            // const comments = value.generalComment
            const response = await createOrder(
                unitTypeSplit[1],
                shipTo,
                unitTypeSplit[0],
                transaction,
                header,
                lines.join(itemSeparator),
                comments.join(commentSeparator),
                notes.join(commentSeparator)
            )

            // If Yardi source then create cXML file
            if (yardiSource) {
                const result = await saveYardiFile()
                console.log({
                    result,
                })
                // TODO: Send cXML file to Yardi
            }
            console.log(response)
            toast({
                title: 'Order',
                description: response.ERROR,
            })
            form.reset()
        },
        validatorAdapter: zodValidator(),
        validators: {
            onChange: placeOrderFormValidation,
            onMount: placeOrderFormValidation,
        },
        defaultValues: {
            orderedBy: userData?.userDetails?.DisplayName,
            email: userData?.userDetails?.Email,
            poNo: '',
            occupied: '',
            moveInDate: undefined,
            anyTime: 'am',
            requestedInstallDate: undefined,
            onsiteContact: '',
            onsiteContactInfo: '',
            streetAddress: '',
            buildingNumber: '',
            unitNumber: '',
            generalComment: '',
            style: '',
            color: '',
            items: undefined,
        },
    })

    const occupiedField = form.useField({
        name: 'occupied',
    })

    const moveInDateField = form.useField({
        name: 'moveInDate',
    })

    const anyTimeField = form.useField({
        name: 'anyTime',
    })

    const itemsField = form.useField({
        name: 'items',
    })

    const orderedByField = form.useField({
        name: 'orderedBy',
    })

    const emailField = form.useField({
        name: 'email',
    })

    const poNoField = form.useField({
        name: 'poNo',
    })

    const requestedInstallDateField = form.useField({
        name: 'requestedInstallDate',
    })

    const onsiteContactField = form.useField({
        name: 'onsiteContact',
    })

    const onsiteContactInfoField = form.useField({
        name: 'onsiteContactInfo',
    })

    const streetAddressField = form.useField({
        name: 'streetAddress',
    })

    const buildingNumberField = form.useField({
        name: 'buildingNumber',
    })

    const unitNumberField = form.useField({
        name: 'unitNumber',
    })

    const generalCommentField = form.useField({
        name: 'generalComment',
    })

    const {
        data: labors,
        isLoading: isLaborLoading,
        isFetching: isLaborFetching,
    } = useQuery({
        queryKey: ['getLabor', laborMaterialFetchParams, userData],
        queryFn: () => getLabor(laborMaterialFetchParams),
        enabled: Boolean(
            laborMaterialFetchParams.customer &&
                laborMaterialFetchParams.property &&
                laborMaterialFetchParams.shipTo
        ),
        staleTime: Infinity,
        select(data) {
            return createLaborNodeList(data)
        },
    })

    const {
        data: materials,
        isLoading: isMaterialLoading,
        isFetching: isMaterialFetching,
    } = useQuery({
        queryKey: ['getMaterial', laborMaterialFetchParams],
        queryFn: () => getMaterial(laborMaterialFetchParams),
        enabled: Boolean(
            laborMaterialFetchParams.customer &&
                laborMaterialFetchParams.property &&
                laborMaterialFetchParams.shipTo
        ),
        staleTime: Infinity,
        select(data) {
            const groupedMaterial = groupByDeep(data, [
                'WEBNAME',
                'REFSTYLE',
            ]) as AccordianMaterialList
            return createMaterialNodeList(groupedMaterial)
        },
    })

    const createMaterialNodeList = (collection: AccordianMaterialList) => {
        const nodeList: ITreeNode = {
            name: '',
            children: [],
        }
        Object.entries(collection).forEach(([key, material]) => {
            const node: ITreeNode = {
                name: key,
                id: key,
                children: [],
            }
            Object.keys(material).map((matKey, index) => {
                const materialStyle = material[matKey]
                const subNode: ITreeNode = {
                    name: `${matKey} ${material[matKey][0].REFCOLOR}`,
                    id: Number(materialStyle[index].ITEMLINE).toString(),
                    children: [],
                }
                if (materialStyle[index]?.WEBNAMECOMMENT?.length !== 0) {
                    materialStyle.map((mat, index) => {
                        subNode.children?.push({
                            name: mat.WEBNAMECOMMENT.trim(),
                            id: `${Number(mat.ITEMLINE)}___${index + 1}`,
                            children: [],
                        })
                    })
                } else {
                    subNode.name = matKey + ' ' + materialStyle[index]?.REFCOLOR
                    subNode.id = `${Number(materialStyle[index]?.ITEMLINE)}___${index + 1}`
                }
                node.children?.push(subNode)
            })
            nodeList.children?.push(node)
        })
        return nodeList
    }

    const createLaborNodeList = (collection: GetLaborAPIResponse) => {
        const data = collection[0]
        return {
            name: 'LABOR',
            id: 'LABOR',
            children: [
                {
                    name: data.WEBNAME.trim(),
                    id: `${Number(data.ITEMLINE)}___0`,
                    children: [],
                },
            ],
        }
    }

    const shipToItems = useMemo(() => {
        if (!customerData?.length) {
            return []
        }
        return customerData?.map((customer) => ({
            id: customer.C_CID,
            value: customer.C_CID ?? '',
            label: `${customer.C_CID} - ${customer.C_NAME} (${customer.C_ADDR1})`,
        }))
    }, [customerData])

    const unitTypeList = useMemo(() => {
        if (pordFileData?.length) {
            return pordFileData.map((unitType) => ({
                label: `${unitType.PDMH_WEBNAME}`,
                value: unitType.PDMO_SHCODE + '___' + unitType.PDMO_PROPTYPE,
            })) as unknown as ShipToUnitTypeDropDown[]
        }
        return []
    }, [pordFileData])

    const isBtnDisabled =
        !(!findingForm.state.isPristine && findingForm.state.isValid) ||
        findingForm.state.isSubmitting ||
        isCustomerLoading ||
        isPordFileLoading ||
        isLaborLoading ||
        isMaterialLoading ||
        isLaborFetching ||
        isMaterialFetching

    const isBtnLoading =
        isCustomerLoading ||
        isPordFileLoading ||
        isLaborLoading ||
        isMaterialLoading ||
        isLaborFetching ||
        isMaterialFetching

    return (
        <div className="flex flex-col items-stretch justify-start flex-1 mt-5 w-4/5">
            <div className="grid grid-cols-1">
                <h1 className="text-wool font-serif md:text-4xl text-xl font-bold">
                    Place Order
                </h1>
                <div className="mt-6 flex justify-between gap-5">
                    <div className="flex-1">
                        <SelectWrapper
                            data={shipToItems}
                            label="Ship To"
                            classNames={{
                                label: ['text-sm font-normal font-sans'],
                            }}
                            placeholder={'Select Ship To'}
                            otherProps={{
                                selectRootProps: {
                                    onValueChange: shipToField.handleChange,
                                    value: shipToField.state.value || undefined,
                                    disabled: isPordFileLoading,
                                    onOpenChange: shipToField.handleBlur,
                                },
                                selectContentProps: {
                                    className: 'text-sm font-sans font-normal',
                                },
                                selectTriggerProps: {
                                    className: `text-sm font-sans font-normal focus:!ring-custom-primary_font ${shipToField.state.meta.isTouched ? '' : 'border-willow-heart'}`,
                                },
                            }}
                        />
                    </div>
                    <div className="flex-[0.5]">
                        <SelectWrapper
                            data={unitTypeList}
                            label="Unit Type"
                            classNames={{
                                label: ['text-sm font-normal font-sans'],
                            }}
                            placeholder={'Select unit type'}
                            otherProps={{
                                selectRootProps: {
                                    onValueChange: unitTypeField.handleChange,
                                    value:
                                        unitTypeField.state.value || undefined,
                                    disabled: isPordFileLoading,
                                    onOpenChange: unitTypeField.handleBlur,
                                },
                                selectContentProps: {
                                    className: 'text-sm font-sans font-normal',
                                },
                                selectTriggerProps: {
                                    className: `text-sm font-sans font-normal focus:!ring-custom-primary_font ${unitTypeField.state.meta.isTouched ? '' : 'border-willow-heart'}`,
                                },
                            }}
                        />
                    </div>
                    <div className="place-content-end sm:text-end text-center mt-5">
                        <PrimaryButton
                            type="submit"
                            title="Search"
                            isLoading={isBtnLoading}
                            disabled={isBtnDisabled}
                            onClick={() => {
                                findingForm.handleSubmit()
                            }}
                        />
                    </div>
                </div>
                {(isCustomerLoading || isPordFileLoading) && (
                    <LoadingComponent classNames="absolute top-[50%] left-[50%] w-6 h-6 mt-10 m-auto" />
                )}
                {!isLaborLoading &&
                !isMaterialLoading &&
                findingForm.state.isSubmitted ? (
                    <div className="grid grid-cols-1 mt-5">
                        <Card>
                            <CardContent className="md:p-6 sm:p-4 p-3">
                                <div className="grid md:grid-cols-2 grid-cols-1 gap-2 md:divide-x divide-x-0 md:divide-y-0 divide-y !divide-[#EDEDED]">
                                    <div>
                                        <h1 className="font-sans md:text-xl sm:text-lg text-md font-bold md:mb-4 sm:mb-2 mb-0">
                                            Ship To
                                        </h1>
                                        <div className="grid grid-cols-2 sm:gap-4 gap-3">
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Location:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_CID +
                                                        ' - ' +
                                                        customer?.C_NAME}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Zip:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_ZIP}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Contact:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_CONTACT}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Phone 1:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_PHONE}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Address:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_ADDR1}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Phone 2:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_PHONE2}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Building:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_ADDR2}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Fax:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_FAX}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                City / State:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_CITY +
                                                        ', ' +
                                                        customer?.C_STATE}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Property Type:{' '}
                                                <span className="font-normal">
                                                    {property?.PDMH_WEBNAME}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:ps-8 md:pt-0 sm:pt-5 pt-2">
                                        <h1 className="font-sans md:text-xl sm:text-lg text-md font-bold md:mb-4 sm:mb-2 mb-0">
                                            Bill To
                                        </h1>
                                        <div className="grid grid-cols-2 gap-4">
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Contact:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_CONTACT}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Zip:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_ZIP}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Address:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_ADDR1}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Phone 1:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_PHONE}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Building:{' '}
                                                <span className="font-normal"></span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Phone 2:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_PHONE2}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                City / State:{' '}
                                                <span className="font-normal">
                                                    {customer?.C_CITY +
                                                        ', ' +
                                                        customer?.C_STATE}
                                                </span>
                                            </div>
                                            <div className="font-bold sm:text-sm text-xs font-sans">
                                                Fax:{' '}
                                                <span className="font-normal"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Separator className="md:my-10 sm:my-5 my-3" />
                                <h1 className="font-sans md:text-xl sm:text-lg text-md font-bold md:mb-4 sm:mb-2 mb-0">
                                    Order Header
                                </h1>
                                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-3">
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <InputField
                                                id="orderedBy"
                                                label="Ordered By"
                                                labelClassName="my-2"
                                                required
                                                className={`text-sm font-sans !font-normal mt-2 focus:!ring-custom-primary_font ${orderedByField.state.meta.isTouched ? '' : 'border-willow-heart'}`}
                                                value={
                                                    orderedByField.state.value
                                                }
                                                onBlur={
                                                    orderedByField.handleBlur
                                                }
                                                onChange={(e) => {
                                                    orderedByField.handleChange(
                                                        e.target.value
                                                    )
                                                }}
                                            ></InputField>
                                            <>
                                                {orderedByField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            orderedByField.state
                                                                .meta.errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {orderedByField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <InputField
                                                id="email"
                                                label="Email "
                                                labelClassName="my-2"
                                                required
                                                className={`text-sm font-sans font-normal mt-2 focus:!ring-custom-primary_font ${emailField.state.meta.isTouched ? '' : 'border-willow-heart'}`}
                                                value={emailField.state.value}
                                                onBlur={emailField.handleBlur}
                                                onChange={(e) =>
                                                    emailField.handleChange(
                                                        e.target.value
                                                    )
                                                }
                                            ></InputField>
                                            <>
                                                {emailField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            emailField.state
                                                                .meta.errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {emailField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <InputField
                                                id="poNo"
                                                label="P.O. Number"
                                                labelClassName="my-2"
                                                required
                                                className={`text-sm font-sans font-normal mt-2 focus:!ring-custom-primary_font`}
                                                value={poNoField.state.value}
                                                onBlur={poNoField.handleBlur}
                                                onChange={(e) =>
                                                    poNoField.handleChange(
                                                        e.target.value
                                                    )
                                                }
                                            ></InputField>
                                            <>
                                                {poNoField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            poNoField.state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {poNoField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <SelectWrapper
                                            data={[
                                                {
                                                    id: '1',
                                                    value: '1',
                                                    label: 'Vacant',
                                                },
                                                {
                                                    id: '2',
                                                    value: '2',
                                                    label: 'Occupied - Resident moves furniture',
                                                },
                                            ]}
                                            label="Occupied"
                                            required={true}
                                            classNames={{
                                                label: [
                                                    'text-xs font-normal font-sans',
                                                ],
                                            }}
                                            otherProps={{
                                                selectRootProps: {
                                                    onValueChange:
                                                        occupiedField.handleChange,
                                                    value:
                                                        occupiedField.state
                                                            .value || undefined,
                                                    onOpenChange:
                                                        occupiedField.handleBlur,
                                                },
                                                selectContentProps: {
                                                    className:
                                                        'text-sm font-sans font-normal',
                                                },
                                                selectTriggerProps: {
                                                    className: `mt-2 text-sm font-sans font-normal focus:!ring-custom-primary_font ${occupiedField.state.meta.isTouched ? '' : 'border-willow-heart'}`,
                                                },
                                            }}
                                        />
                                        <>
                                            {occupiedField.state.meta
                                                .isTouched ? (
                                                <span className="text-xs text-willow-danger">
                                                    {
                                                        occupiedField.state.meta
                                                            .errors
                                                    }
                                                </span>
                                            ) : null}
                                            {occupiedField.state.meta
                                                .isValidating
                                                ? 'Validating...'
                                                : null}
                                        </>
                                    </div>
                                    <div className="font-normal text-xs flex-1 font-sans">
                                        <>
                                            <Label htmlFor="moveInDate">
                                                Move In Date (If Applicable)
                                            </Label>
                                            <div className="flex gap-2">
                                                <DatePicker
                                                    id="moveInDate"
                                                    disabledDates={(date) =>
                                                        today > date
                                                    }
                                                    selected={
                                                        moveInDateField.state
                                                            .value
                                                    }
                                                    onDayBlur={
                                                        moveInDateField.handleBlur
                                                    }
                                                    onDayClick={
                                                        moveInDateField.handleChange
                                                    }
                                                    className="capitalize rounded w-full text-sm h-auto p-1 px-2 pr-0 font-sans font-normal mt-2 focus:!ring-custom-primary_font flex-row-reverse justify-between basis-96 "
                                                />
                                                <SelectWrapper
                                                    data={[
                                                        {
                                                            id: 'am',
                                                            value: 'am',
                                                            label: 'AM',
                                                        },
                                                        {
                                                            id: 'pm',
                                                            value: 'pm',
                                                            label: 'PM',
                                                        },
                                                    ]}
                                                    placeholder="AM/PM"
                                                    otherProps={{
                                                        selectRootProps: {
                                                            onValueChange:
                                                                anyTimeField.handleChange,
                                                            value:
                                                                anyTimeField
                                                                    .state
                                                                    .value ||
                                                                undefined,
                                                            onOpenChange:
                                                                anyTimeField.handleBlur,
                                                        },
                                                        selectContentProps: {
                                                            className:
                                                                'text-sm font-sans font-normal',
                                                        },
                                                        selectTriggerProps: {
                                                            className: `mt-2 text-sm font-sans font-normal focus:!ring-custom-primary_font ${anyTimeField.state.meta.isTouched ? '' : 'border-willow-heart'}`,
                                                        },
                                                    }}
                                                />
                                            </div>
                                            <>
                                                {moveInDateField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            moveInDateField
                                                                .state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {moveInDateField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <Label
                                                htmlFor="requestedInstallDate"
                                                className="my-2"
                                            >
                                                Requested Install Date
                                                <span className="text-willow-heart">
                                                    *
                                                </span>
                                            </Label>
                                            <DatePicker
                                                id="requestedInstallDate"
                                                selected={
                                                    requestedInstallDateField
                                                        .state.value
                                                }
                                                disabledDates={(date) =>
                                                    today > date
                                                }
                                                onDayBlur={
                                                    requestedInstallDateField.handleBlur
                                                }
                                                onDayClick={
                                                    requestedInstallDateField.handleChange
                                                }
                                                className="capitalize rounded w-full text-sm h-auto p-1 px-2 pr-0 font-sans font-normal mt-2 focus:!ring-custom-primary_font flex-row-reverse justify-between basis-96"
                                            />
                                            <>
                                                {requestedInstallDateField.state
                                                    .meta.isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            requestedInstallDateField
                                                                .state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {requestedInstallDateField.state
                                                    .meta.isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <InputField
                                                id="onsiteContact"
                                                label="Onsite Contact"
                                                labelClassName="my-2"
                                                className="text-sm font-sans font-normal mt-2 focus:!ring-custom-primary_font"
                                                value={
                                                    onsiteContactField.state
                                                        .value
                                                }
                                                onBlur={
                                                    onsiteContactField.handleBlur
                                                }
                                                onChange={(e) =>
                                                    onsiteContactField.handleChange(
                                                        e.target.value
                                                    )
                                                }
                                            ></InputField>
                                            <>
                                                {onsiteContactField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            onsiteContactField
                                                                .state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {onsiteContactField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <InputField
                                                id="onsiteContactInfo"
                                                label="Onsite Contact Info"
                                                labelClassName="my-2"
                                                className="text-sm font-sans font-normal mt-2 focus:!ring-custom-primary_font"
                                                value={
                                                    onsiteContactInfoField.state
                                                        .value
                                                }
                                                onBlur={
                                                    onsiteContactInfoField.handleBlur
                                                }
                                                onChange={(e) =>
                                                    onsiteContactInfoField.handleChange(
                                                        e.target.value
                                                    )
                                                }
                                            ></InputField>
                                            <>
                                                {onsiteContactInfoField.state
                                                    .meta.isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            onsiteContactInfoField
                                                                .state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {onsiteContactInfoField.state
                                                    .meta.isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                </div>
                                <h1 className="font-sans md:text-xl sm:text-lg mt-6 text-md font-bold md:mb-4 sm:mb-2 mb-0">
                                    Ship To
                                </h1>
                                <div className="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 sm:gap-4 gap-3">
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <InputField
                                                id="streetAddress"
                                                label="Street Address (If Different)"
                                                labelClassName="my-2"
                                                className="text-sm font-sans !font-normal mt-2 focus:!ring-custom-primary_font"
                                                value={
                                                    streetAddressField.state
                                                        .value
                                                }
                                                onBlur={
                                                    streetAddressField.handleBlur
                                                }
                                                onChange={(e) =>
                                                    streetAddressField.handleChange(
                                                        e.target.value
                                                    )
                                                }
                                            ></InputField>
                                            <>
                                                {streetAddressField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            streetAddressField
                                                                .state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {streetAddressField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <InputField
                                                id="buildingNumber"
                                                label="Building Number"
                                                labelClassName="my-2"
                                                className="text-sm font-sans font-normal mt-2 focus:!ring-custom-primary_font"
                                                value={
                                                    buildingNumberField.state
                                                        .value
                                                }
                                                onBlur={
                                                    buildingNumberField.handleBlur
                                                }
                                                onChange={(e) =>
                                                    buildingNumberField.handleChange(
                                                        e.target.value
                                                    )
                                                }
                                            ></InputField>
                                            <>
                                                {buildingNumberField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            buildingNumberField
                                                                .state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {buildingNumberField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans">
                                        <>
                                            <InputField
                                                id="unitNumber"
                                                label="Unit Number"
                                                labelClassName="my-2"
                                                className="text-sm font-sans font-normal mt-2 focus:!ring-custom-primary_font"
                                                required
                                                value={
                                                    unitNumberField.state.value
                                                }
                                                onBlur={
                                                    unitNumberField.handleBlur
                                                }
                                                onChange={(e) =>
                                                    unitNumberField.handleChange(
                                                        e.target.value
                                                    )
                                                }
                                            ></InputField>
                                            <>
                                                {unitNumberField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            unitNumberField
                                                                .state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {unitNumberField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                    <div className="font-normal text-xs font-sans md:col-span-3 sm:col-span-2 col-span-1">
                                        <>
                                            <InputField
                                                id="generalComment"
                                                fieldType="textarea"
                                                label="General Comment"
                                                labelClassName="my-2"
                                                className="text-sm font-sans font-normal mt-2 focus:!ring-custom-primary_font"
                                                rows={4}
                                                value={
                                                    generalCommentField.state
                                                        .value
                                                }
                                                onBlur={
                                                    generalCommentField.handleBlur
                                                }
                                                onChange={(e) =>
                                                    generalCommentField.handleChange(
                                                        e.target.value
                                                    )
                                                }
                                            ></InputField>
                                            <>
                                                {generalCommentField.state.meta
                                                    .isTouched ? (
                                                    <span className="text-xs text-willow-danger">
                                                        {
                                                            generalCommentField
                                                                .state.meta
                                                                .errors
                                                        }
                                                    </span>
                                                ) : null}
                                                {generalCommentField.state.meta
                                                    .isValidating
                                                    ? 'Validating...'
                                                    : null}
                                            </>
                                        </>
                                    </div>
                                </div>
                                <Separator className="md:my-10 sm:my-5 my-3" />
                                <h1 className="font-sans md:text-xl sm:text-lg text-md font-bold md:mb-4 sm:mb-2 mb-0">
                                    Items in Order
                                </h1>
                                <div className="flex bg-willow-ash-lightest rounded-xl">
                                    {!isLaborLoading && !isMaterialLoading ? (
                                        <OrderItems
                                            labors={labors!}
                                            materials={materials!}
                                            onItemsSelected={(item) => {
                                                const currentVal: Record<
                                                    string,
                                                    boolean
                                                > = structuredClone(
                                                    itemsField.state.value || {}
                                                )
                                                if (currentVal[item]) {
                                                    currentVal[item] = false
                                                } else {
                                                    currentVal[item] = true
                                                }
                                                itemsField.setValue(currentVal)
                                                if (
                                                    Object.keys(currentVal)
                                                        .length === 0
                                                ) {
                                                    setOrderItemsRequired(true)
                                                }
                                            }}
                                        />
                                    ) : null}
                                </div>
                                <>
                                    {orderItemsRequired ? (
                                        <span className="text-xs text-willow-danger">
                                            Order Items is required
                                        </span>
                                    ) : null}
                                </>
                            </CardContent>
                            <CardFooter className="grid grid-cols-1 sm:text-end text-center">
                                <div className="flex justify-end gap-6">
                                    <SecondaryButton
                                        title="Cancel"
                                        onClick={() => {
                                            form.reset()
                                        }}
                                    />
                                    <PrimaryButton
                                        type="submit"
                                        title="Submit"
                                        isLoading={form.state.isSubmitting}
                                        disabled={
                                            !(
                                                !form.state.isPristine &&
                                                form.state.isValid
                                            ) || form.state.isSubmitting
                                        }
                                        onClick={() => {
                                            form.handleSubmit()
                                        }}
                                    />
                                </div>
                            </CardFooter>
                        </Card>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default PlaceOrder
