import { createFileRoute } from '@tanstack/react-router'
import z from 'zod'

import Login from '../../../pages/login'

export const Route = createFileRoute('/$clientid/_onboarding/login')({
    component: Login,
    validateSearch: (search) =>
        z
            .object({
                source: z.string().trim().optional(),
            })
            .optional()
            .parse(search),
})
