import { Button, ButtonProps } from '@broadlume/willow-ui'

const SecondaryButton: React.FC<
    ButtonProps & React.RefAttributes<HTMLButtonElement>
> = ({ title, children, ...rest }) => (
    <Button
        variant="outline"
        className="custom-secondary-button custom-secondary-font text-xs border border-willow-ash"
        {...rest}
    >
        {title ?? children}
    </Button>
)

export { SecondaryButton }
