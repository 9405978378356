import { useRouterState } from '@tanstack/react-router'

const Footer = () => {
    const currentYear = new Date().getFullYear()
    const router = useRouterState()
    const pathname = router.location.pathname

    return (
        <>
            <footer
                className={`${pathname !== '/login' && pathname !== '/forgotpassword' && pathname !== '/resetpassword' ? 'relative' : 'sticky'} inset-x-0 bottom-0 bg-willow-ash py-3 pb-2 md:px-12 px-4`}
            >
                <div className="grid grid-cols-3">
                    <div className="col-span-2">
                        <div className="">
                            <div className="mb-2">
                                <img
                                    src="/broadlume-logo.png"
                                    alt="broadlume_logo"
                                    width={175}
                                />
                            </div>

                            <div className="text-sm sm:text-left mb-2 text-willow-ash-medium">
                                @Copyright {currentYear}. BroadlumeBMS. All
                                rights reserved.
                            </div>

                            <div className="grid lg:grid-cols-4 md:grid-cols-2 mb-2 gap-2 text-willow-ash-medium text-sm">
                                <div className="md:text-sm text-xs">
                                    <span className="text-willow-gold">
                                        Location:{' '}
                                    </span>
                                    <a
                                        className="text-willow-wool-light"
                                        href="#"
                                    >
                                        Hammond, LA, USA
                                    </a>
                                </div>
                                <div className="md:text-sm text-xs">
                                    <span className="text-willow-gold">
                                        Sales:{' '}
                                    </span>
                                    <a
                                        className="text-willow-wool-light"
                                        href="#"
                                    >
                                        +1 (850) 303-1756
                                    </a>
                                </div>
                                <div className="md:text-sm text-xs">
                                    <span className="text-willow-gold">
                                        Support:{' '}
                                    </span>
                                    <a
                                        className="text-willow-wool-light"
                                        href="#"
                                    >
                                        support@rmaster.com
                                    </a>
                                </div>
                                <div className="md:text-sm text-xs">
                                    <span className="text-willow-gold">
                                        More Info:{' '}
                                    </span>
                                    <a
                                        className="text-willow-wool-light"
                                        href="#"
                                    >
                                        info@rmaster.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="justify-end grid grid-rows grid-flow-col md:gap-4 gap-2 content-center">
                        <a href="#">
                            <img
                                src="/instagram.png"
                                alt="broadlume_logo"
                                width={21}
                                height={21}
                            />
                        </a>
                        <a href="#">
                            <img
                                src="/x.png"
                                alt="broadlume_logo"
                                width={22}
                                height={21}
                            />
                        </a>
                        <a href="#">
                            <img
                                src="/meta.png"
                                alt="broadlume_logo"
                                width={12}
                                height={21}
                            />
                        </a>
                        <a href="#">
                            <img
                                src="/linkedin.png"
                                alt="broadlume_logo"
                                width={22}
                                height={21}
                            />
                        </a>
                        <a href="#">
                            <img
                                src="/youtube.png"
                                alt="broadlume_logo"
                                width={28}
                                height={21}
                            />
                        </a>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
