import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import Resetpassword from '../../../pages/resetpassword'

export const Route = createFileRoute('/$clientid/_onboarding/resetpassword')({
    component: Resetpassword,
    validateSearch: (search) =>
        z
            .object({
                email: z.string().email(),
            })
            .parse(search),
})
