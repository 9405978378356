import {
    Card,
    CardContent,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@broadlume/willow-ui'
import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { getRouteApi, useRouter } from '@tanstack/react-router'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { z } from 'zod'

import { useState } from 'react'
import { FormTextInput } from '../components/FormInput'
import { PasswordHelperComponent } from '../components/PasswordErrorHelper'
import { PrimaryButton } from '../components/primary-button'
import { useCustomToast } from '../components/useCustomToast'
import { axiosInstance } from '../services/api'
import {
    codeSchema,
    emailSchema,
    passwordSchema,
    resetPasswordSchema,
} from '../utils/validators/schemas'
import { OnboardingContainer } from './onboarding'

const route = getRouteApi('/$clientid/_onboarding/resetpassword')
export const Resetpassword = () => {
    const routeParams = route.useParams()
    const queryParams = route.useSearch()
    const router1 = useRouter()
    const [showPasswordPopup, setShowPasswordPopup] = useState(false)
    console.log('showPasswordPopup===', showPasswordPopup)
    const { toast } = useCustomToast()
    const navigate = route.useNavigate()
    const setPwdMutation = useMutation({
        mutationKey: ['setPassword'],
        mutationFn: async ({
            code,
            password,
            email,
        }: {
            code: string
            password: string
            email: string
        }) => {
            try {
                const result = await axiosInstance.post(`/resetPassword`, {
                    email,
                    password,
                    module: routeParams.clientid,
                    otp: code,
                })

                if (result.status !== 200) {
                    throw result.data.message
                }

                toast({
                    title: '✅ Password changed sucessfully!',
                    description: 'Please login to continue',
                })
                setTimeout(() => {
                    navigate({
                        to: '/$clientid/login',
                        params: routeParams,
                    })
                }, 2000)
            } catch (error) {
                console.log(error)
                toast({
                    title: 'Invalid Request',
                    description: error?.message?.name,
                })
            }
        },
    })
    const form = useForm({
        defaultValues: {
            confirmPassword: '',
            password: '',
            code: '',
            email: queryParams.email,
        },
        validators: {
            onMount: resetPasswordSchema,
        },
        validatorAdapter: zodValidator(),
        onSubmit: async ({ value }) => {
            if (value.password !== value.confirmPassword) {
                console.error('passwords should match')
                toast({
                    title: error?.response?.data?.message,
                })
            }
            await setPwdMutation.mutateAsync({
                email: value.email,
                password: value.password,
                code: value.code,
            })
            router1.invalidate()
        },
    })

    console.log('form====', form.state)

    return (
        <OnboardingContainer>
            <Card className="pt-6 w-2/5 sm:w-4/5 md:w-1/2 lg:w-1/3 rounded-2xl border-none shadow-[0px_15px_50px_0px_#0000001A] p-2 m-auto">
                <CardHeader>
                    <CardTitle className="text-center text-2xl font-bold  not-italic font-sans">
                        Reset Password
                    </CardTitle>
                </CardHeader>
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        form.handleSubmit()
                    }}
                >
                    <CardContent className="pb-0 md:p-1 px-1 flex flex-col">
                        <FormTextInput
                            form={form}
                            name="code"
                            validators={{
                                onChange: codeSchema,
                            }}
                            otherProps={{
                                type: 'text',
                                label: 'Confirmation Code',
                                maxLength: 6,
                            }}
                        />
                        <FormTextInput
                            form={form}
                            name="email"
                            validators={{
                                onChange: emailSchema,
                            }}
                            otherProps={{
                                label: 'Email',
                                type: 'email',
                            }}
                        />
                        <FormTextInput
                            form={form}
                            name="password"
                            validators={{
                                onChange: passwordSchema,
                                onBlur: () => {
                                    setShowPasswordPopup(false)
                                },
                            }}
                            otherProps={{
                                label: 'Password',
                                type: 'password',
                                autoComplete: 'current-password',
                                // onBlur: () => {
                                //     setShowPasswordPopup(false)
                                // },
                                // onFocus: () => setShowPasswordPopup(true),
                                onFocus: () => {
                                    setShowPasswordPopup(true)
                                },
                                customErrorComponent: (props) => (
                                    <PasswordHelperComponent
                                        showPasswordPopup={showPasswordPopup}
                                        {...props}
                                    />
                                ),
                            }}
                        />
                        <FormTextInput
                            form={form}
                            validators={{
                                onChangeListenTo: ['password'],
                                onChange: z
                                    .string({
                                        message: 'Confirm Password is required',
                                    })
                                    .refine(
                                        (data) =>
                                            form.getFieldValue('password') ===
                                            data,
                                        {
                                            message: 'Passwords do not match!',
                                            path: ['confirmPassword'],
                                        }
                                    ),
                            }}
                            name="confirmPassword"
                            otherProps={{
                                label: 'Confirm Password',
                                type: 'password',
                            }}
                        />
                    </CardContent>
                    <CardFooter className="grid grid-cols-1 py-5">
                        <PrimaryButton
                            type="submit"
                            title="Submit"
                            className="text-center py-5 w-fit px-12 justify-self-center"
                            isLoading={form.state.isSubmitting}
                            disabled={
                                !(
                                    !form.state.isPristine && form.state.isValid
                                ) || form.state.isSubmitting
                            }
                        />
                    </CardFooter>
                </form>
            </Card>
        </OnboardingContainer>
    )
}

export default Resetpassword
