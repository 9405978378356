import { DeepKeys, useForm } from '@tanstack/react-form'
import { ComponentProps } from 'react'
import { CustomInput } from './custom-input'

interface TextInputProps<T> {
    form: ReturnType<typeof useForm<T>>
    name: DeepKeys<T>
}

const ALLOW_NUMBERS_REGEX = /\D/g

export const FormTextInput = <T,>({
    form,
    name,
    otherProps,
    ...otherFormFieldProps
}: TextInputProps<T> & {
    otherProps: Omit<
        ComponentProps<typeof CustomInput> & { isResetPasswordPage: boolean },
        'id'
    >
}) => {
    return (
        <div className="my-1.5">
            <form.Field
                name={name}
                children={(field) => {
                    // console.log('field errors===', field.state.meta.errors)
                    return (
                        <>
                            <CustomInput
                                id={field.name}
                                name={field.name}
                                value={field.state.value}
                                onChange={(e) => {
                                    // allow only numbers
                                    if (field.name === 'code') {
                                        field.handleChange(
                                            e.target.value.replace(
                                                ALLOW_NUMBERS_REGEX,
                                                ''
                                            ) as typeof field.handleChange.arguments
                                        )
                                    } else {
                                        field.handleChange(
                                            e.target
                                                .value as typeof field.handleChange.arguments
                                        )
                                    }
                                }}
                                isTouched={field.state.meta.isTouched}
                                errors={field.state.meta.errors}
                                onBlur={field.handleBlur}
                                {...otherProps}
                            />
                        </>
                    )
                }}
                {...otherFormFieldProps}
            />
        </div>
    )
}
