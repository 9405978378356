import { DatePicker, Label } from '@broadlume/willow-ui'
import { IoIosAlert } from 'react-icons/io'
import { filterSchemaValidation } from '../../utils/validators/schemas'
import { useField } from '@tanstack/react-form'

export const DateRangePicker = ({
    startEndDateField,
    classNames = { root: '', label: '', datepicker: '' },
}: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    startEndDateField: ReturnType<
        typeof useField<filterSchemaValidation, 'startEndDate'>
    >
    classNames?: {
        root?: string
        label?: string
        datepicker?: string
    }
}) => {
    const handleDateRange = (e: Date) => {
        if (
            startEndDateField.state.value.from &&
            !startEndDateField.state.value.to
        ) {
            if (e < startEndDateField.state.value.from) {
                startEndDateField.handleChange({
                    from: e,
                    to: undefined,
                })
            } else {
                startEndDateField.handleChange({
                    from: startEndDateField.state.value.from,
                    to: e,
                })
                // do modal close here.
            }
        } else if (
            startEndDateField.state.value.from &&
            startEndDateField.state.value.to
        ) {
            startEndDateField.handleChange({
                from: e,
                to: undefined,
            })
        }
    }
    return (
        <div className={`flex flex-col mt-6 mb-2 ${classNames.root}`}>
            <Label
                className={`mb-2 text-sm ${classNames.label}`}
                htmlFor="startEndDate"
            >
                Select Date Range
            </Label>
            <DatePicker
                id="startEndDate"
                selected={startEndDateField.state.value}
                onDayBlur={startEndDateField.handleBlur}
                numberOfMonths={2}
                onDayClick={handleDateRange}
                className={`rounded-sm border-1 bg-willow-ash-lightest mb-2 ${classNames.datepicker}`}
                mode="range"
                // max={365}
            />

            {startEndDateField?.state?.meta?.errors.map((item) => (
                <div className="text-willow-danger text-sm flex items-center justify-start">
                    <IoIosAlert className="mr-2" width={4} />
                    <p>{item}</p>
                </div>
            ))}
        </div>
    )
}
