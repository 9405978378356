import { useQuery } from '@tanstack/react-query'
import { useAuthHook } from './order-analysis.hook'
import { getBuildingFromInvoice } from '../../services/orders'

export const useGetBuildingFromInvoice = () => {
    const { data: userData } = useAuthHook()

    return useQuery({
        queryKey: ['getBuildingFromInvoice', userData],
        queryFn: async () => {
            const data = await getBuildingFromInvoice()
            const buildings: {
                [key: string]: { label: string; value: string }
            } = {}
            const units: {
                [key: string]: { label: string; value: string }
            } = {}
            data.forEach((item) => {
                const addrArray = item.DMH_SHADDR2.split('#')
                const buildingValue = addrArray[0].replace(/ /g, '')
                buildingValue.length &&
                    (buildings[buildingValue] = {
                        label: buildingValue,
                        value: buildingValue,
                        ...item,
                    })

                addrArray[1].length &&
                    (units[addrArray[1]] = {
                        label: addrArray[1],
                        value: addrArray[1],
                        ...item,
                    })
            })

            const allBuildings = [
                { label: 'Select All', value: 'all' },
                ...Object.values(buildings),
            ]

            const allUnits = [
                { label: 'Select All', value: 'all' },
                ...Object.values(units),
            ]

            return {
                buildings: allBuildings,
                units: allUnits,
            }
        },
        staleTime: 180000,
    })
}
