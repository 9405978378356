import { Checkbox, Label } from '@broadlume/willow-ui'
import { PropsWithChildren } from 'react'
import TreeView, { flattenTree, NodeId } from 'react-accessible-treeview'
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils'
export interface ITreeNode<M extends IFlatMetadata = IFlatMetadata> {
    id?: NodeId
    name: string
    isBranch?: boolean
    children?: ITreeNode<M>[]
    metadata?: M
}

export const OrderItems: React.FC<
    PropsWithChildren & {
        materials: ITreeNode
        labors: ITreeNode
        onItemsSelected: (value: string) => void
    }
> = ({ materials, labors, onItemsSelected }) => {
    return (
        <div className="p-6 flex flex-col">
            <CustomTreeView
                onItemsSelected={onItemsSelected}
                nodes={{
                    name: '',
                    children: [...(materials?.children || []), labors],
                }}
            />
        </div>
    )
}

const CustomTreeView: React.FC<
    PropsWithChildren & {
        onItemsSelected: (value: string) => void
        nodes: ITreeNode
    }
> = ({ onItemsSelected, nodes }) => {
    const flattenNodes = flattenTree(nodes)
    const defaultExpandedIds = flattenNodes.map((node) => node.id)

    return (
        <TreeView
            data={flattenNodes}
            aria-label="Order Items"
            onSelect={(e) => {
                if (!e.isBranch) {
                    onItemsSelected(e.element.id.toString())
                }
            }}
            multiSelect
            propagateSelect
            propagateSelectUpwards
            defaultExpandedIds={defaultExpandedIds}
            togglableSelect
            nodeRenderer={({
                element,
                isBranch,
                isSelected,
                isHalfSelected,
                level,
                getNodeProps,
                handleSelect,
            }) => {
                return (
                    <div
                        {...getNodeProps({})}
                        style={{
                            marginLeft: 20 * (level - 1),
                            marginTop: isBranch ? 10 : 5,
                        }}
                    >
                        <div data-testid={element.id}>
                            <Checkbox
                                data-testid={'checkbox-' + element.id}
                                {...(isHalfSelected
                                    ? {
                                          checked: 'indeterminate',
                                      }
                                    : isSelected
                                      ? {
                                            checked: true,
                                        }
                                      : {
                                            checked: false,
                                        })}
                                onClick={(e) => {
                                    handleSelect(e)
                                    e.stopPropagation()
                                }}
                            />
                            <Label
                                data-testid={'label-' + element.id}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                {' '}
                                {element.name}
                            </Label>
                        </div>
                    </div>
                )
            }}
        />
    )
}
