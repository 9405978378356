import { useToast } from '@broadlume/willow-ui'

export const useCustomToast = () => {
    const { toast } = useToast()
    const customToast = (...rest: Parameters<typeof toast>) => {
        const newToast = toast(...rest)
        setTimeout(() => {
            newToast.dismiss()
        }, 3500)
    }
    return { toast: customToast }
}
