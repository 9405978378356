type Props = { title: string }
/**
 * A functional component that wraps its children within a styled container.
 *
 * @param {React.PropsWithChildren<Props>} props - The props containing children elements to be rendered inside the wrapper.
 * @returns {JSX.Element} A JSX element containing a header and the provided children.
 */
export const PageWrapper = ({
    title,
    children,
}: React.PropsWithChildren<Props>) => {
    return (
        <div className="flex flex-col items-stretch justify-start flex-1 w-4/5 py-6">
            <div className="grid grid-cols-1">
                <h1 className="text-wool font-serif md:text-4xl text-xl font-bold mb-6">
                    {title}
                </h1>
            </div>
            {children}
        </div>
    )
}
