import { CustomDataTable } from '@broadlume/willow-ui'
import { sortFn } from '.'
import { SelectWrapper } from '../pages/order-analysis'
import { sub } from 'date-fns'
import { OrderSortOrSearchField } from './interfaces/orders.interface'
export const sortByFields: Parameters<typeof SelectWrapper>[0]['data'] = [
    {
        value: 'SLS_SALEAMT',
        id: 'saleAmount',
        label: 'Sale Amount',
    },
    {
        value: 'SLS_SALEQTY',
        id: 'saleQuantity',
        label: 'Sale Quantity',
    },
]

export const orderByFields: Parameters<typeof SelectWrapper>[0]['data'] = [
    {
        value: 'desc',
        id: 'desc',
        label: 'Descending',
    },
    {
        value: 'asc',
        id: 'asc',
        label: 'Ascending',
    },
]

export const selectionFields: Parameters<typeof SelectWrapper>[0]['data'] = [
    {
        value: 'SLS_INVDATE',
        id: 'Invoice Date',
        label: 'Invoice Date',
    },
    {
        value: 'SLS_STYLE',
        id: 'Style',
        label: 'Style',
    },
    {
        value: 'SLS_ORDNO',
        id: 'Job Number',
        label: 'Job Number',
    },
    {
        value: 'SLS_SAL1',
        id: 'Sales Person',
        label: 'Sales Person',
    },
    {
        value: 'SLS_INVNO',
        id: 'Invoice Number',
        label: 'Invoice Number',
    },
    {
        value: 'DMH_SHADDR1',
        id: 'Address',
        label: 'Address',
    },
    {
        value: 'PC_TYPE',
        id: 'Product Type',
        label: 'Product Type',
    },
    {
        value: 'DMI_COLOR',
        id: 'Color',
        label: 'Color',
    },
    {
        value: 'BLDG_ONLY',
        id: 'Building',
        label: 'Building',
    },
    {
        value: 'UNIT_ONLY',
        id: 'Unit',
        label: 'Unit',
    },
    {
        value: 'DMI_SHIPTO',
        id: 'Job Address',
        label: 'Job Address',
    },
    {
        value: 'DMI_SOLDTO',
        id: 'Location',
        label: 'Location',
    },
    {
        value: 'SLS_CUSTID',
        id: 'Customer Name',
        label: 'Customer Name',
    },
]

export const unitData = [
    {
        id: 'selectAllUnit',
        isChecked: true,
        label: 'Select All',
        name: 'selectAll',
    },
    {
        id: '1',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '2',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '3',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
]

export const locationData = [
    {
        id: 'selectAllLocation',
        isChecked: true,
        label: 'Select All',
        name: 'selectAll',
    },
    {
        id: '12',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '23',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '34',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
]

export const buildingData = [
    {
        id: 'selectAllBuilding',
        isChecked: true,
        label: 'Select All',
        name: 'selectAll',
    },
    {
        id: '11',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '22',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
    {
        id: '33',
        name: 'Burlon - chris burlon (7653 cedar Park LN)',
        label: 'Burlon - chris burlon (7653 cedar Park LN)',
        isChecked: true,
    },
]

export const tableColumns: Parameters<typeof CustomDataTable>[0]['columns'] = [
    {
        name: 'Sale Quantity',
        selector: (row): string =>
            (
                Math.round(
                    parseFloat((row as Record<string, string>).saleQty) * 100
                ) / 100
            ).toString(),
        sortable: true,
        sortFunction: sortFn('saleQty'),
        conditionalCellStyles: [
            {
                when: (row) => (row as Record<string, string>).id === 'totals',
                classNames: ['text-willow-gold'],
            },
        ],
    },
    {
        name: 'UOM',
        selector: (row): string => (row as Record<string, string>).uom,
        grow: 0,
        sortable: false,
    },
    {
        name: 'Sale Total',
        selector: (row): string =>
            '$' +
            (
                Math.round(
                    parseFloat((row as Record<string, string>).saleTotal) * 100
                ) / 100
            ).toLocaleString(),
        sortable: true,
        conditionalCellStyles: [
            {
                when: (row) => (row as Record<string, string>).id === 'totals',
                classNames: ['text-willow-gold'],
            },
        ],
        sortFunction: sortFn('saleTotal'),
        grow: 1,
    },
]

export const checkBoxStyling = {
    root: ['mr-2'],
    mainLabel: ['!mb-2 text-sm font-light'],
    checkboxLabel: ['!text-sm font-light my-0.5 !mx-0'],
    checkbox: ['data-[state=checked]:!bg-willow-ribbon !text-base'],
}

export const orderAnalysisFormDefaultValues = {
    // startEndDate: {
    //     from: sub(new Date(), {
    //         years: 1,
    //         days: 1,
    //     }),
    //     to: new Date(),
    // },
    startEndDate: {
        from: sub(new Date('2023-12-02'), {
            days: 365,
        }),
        to: new Date('2023-12-02'),
    },
    // startEndDate: {
    //     from: sub(new Date('2023-12-02'), {
    //         days: 365,
    //     }),
    //     to: new Date('2023-12-02'),
    // },
    // endDate: new Date(),
    sortBy: 'SLS_SALEQTY',
    orderBy: 'desc' as const,
    selection1: undefined,
    selection2: undefined,
    selection3: undefined,
    selection4: undefined,
    selectLocation: [] as string[], //'MANDALAY,0000', 'SURFACES,0000'
    selectBuilding: [] as string[],
    selectUnit: [] as string[], //'5698', '12', '5645'
}

export const tableCustomStyle: Parameters<
    typeof CustomDataTable
>[0]['customStyles'] = {
    rows: {
        style: {
            paddingTop: '5px',
            paddingBottom: '5px',
            cursor: 'pointer',
            backgroundColor: '#FFFFFF',
            '&:nth-child(2n)': {
                backgroundColor: '#FAFAFA',
            },
        },
    },
    headRow: {
        style: {
            height: 'auto',
            minHeight: 'auto',
            backgroundColor: '#FFD990',
        },
    },
    headCells: {
        style: {
            height: '40px',
            backgroundColor: '#FFD990',
            fontSize: '13px',
            fontWeight: 400,
            textTransform: 'capitalize',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            fontFamily: 'moret,serif !importan',
        },
    },
}

export const orderAnalysisTableLegends = [
    {
        dataFrom: 'keys',
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 120,
        translateY: 0,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
            {
                on: 'hover',
                style: {
                    itemOpacity: 1,
                },
            },
        ],
    },
]

export const OrderDetailsMapper = {
    orderDetailsLeftSideMapper: [
        {
            titleKey: 'Order Number',
            valueKey: 'DMO_ORDNO',
        },
        {
            titleKey: 'Order Date',
            valueKey: 'DMH_DATE',
        },
        {
            titleKey: 'Sales Rep',
            valueKey: 'SAL_NAME',
        },
        {
            titleKey: 'Install Date',
            valueKey: 'DMH_INSTALLDATE',
        },
        {
            titleKey: 'Install Time',
            valueKey: 'DMH_INSTALL_TIME',
        },
        {
            titleKey: 'Job Status',
            valueKey: 'DMH_STATUSCODE',
        },
    ],
    orderDetailsRightSideMapper: [
        {
            titleKey: 'Quote Number',
            valueKey: '',
        },
        {
            titleKey: 'Building',
            valueKey: 'DMH_BLDG',
        },
        {
            titleKey: 'PO Number',
            valueKey: 'DMH_CUSTPO',
        },
        {
            titleKey: 'Special Instructions',
            valueKey: 'DMH_SPECIAL1',
        },
        {
            titleKey: 'Order By',
            valueKey: 'DMH_ORDEREDBY',
        },
    ],
}

export const OrderDetailsHeaderMapper = {
    billTo: [
        {
            titleKey: 'Contact',
            valueKey: 'DMH_SHCONTACT',
        },
        {
            titleKey: 'Zip',
            valueKey: 'DMH_SHZIP',
        },
        {
            titleKey: 'Address',
            valueKey: 'DMH_SHADDR1',
        },
        {
            titleKey: 'Phone 1',
            valueKey: 'DMH_SHPHONE1',
        },
        {
            titleKey: 'Building',
            valueKey: 'DMH_BLDG',
        },
        {
            titleKey: 'Phone 2',
            valueKey: '',
        },
        {
            titleKey: 'City / State',
            valueKey: 'DMH_SHCTYST',
        },
        {
            titleKey: 'Fax',
            valueKey: 'DMH_SHFAX',
        },
    ],
    shipTo: [
        {
            titleKey: 'Location',
            valueKey: 'DMH_SHNAME',
        },
        {
            titleKey: 'Zip',
            valueKey: 'DMH_SHZIP',
        },
        {
            titleKey: 'Contact',
            valueKey: 'DMH_SHCONTACT',
        },
        {
            titleKey: 'Phone 1',
            valueKey: 'DMH_SHPHONE1',
        },
        {
            titleKey: 'Address',
            valueKey: 'DMH_SHADDR1',
        },
        {
            titleKey: 'Phone 2',
            valueKey: '',
        },
        {
            titleKey: 'Building',
            valueKey: 'DMH_BLDG',
        },
        {
            titleKey: 'Fax',
            valueKey: 'DMH_SHFAX',
        },
        {
            titleKey: 'City / State',
            valueKey: 'DMH_SHCTYST',
        },
        {
            titleKey: 'Property Type',
            valueKey: 'DMH_SHCODE',
        },
    ],
}

export const PropertyRegex = /[a-zA-Z0-9]{1,}\/\*\\[0-9]{1,}\|\|/gm

export const sortByDefaultValues = [
    {
        id: '1',
        label: 'PO Number',
        value: 'poNumber',
    },
    {
        id: '2',
        label: 'Order Date',
        value: 'orderDate',
    },
    {
        id: '3',
        label: 'Install Date',
        value: 'installDate',
    },
    {
        id: '4',
        label: 'Customer Name',
        value: 'customerName',
    },
    {
        id: '5',
        label: 'Location Code',
        value: 'locationCode',
    },
    {
        id: '6',
        label: 'Order Number',
        value: 'orderNumber',
    },
    {
        id: '7',
        label: 'Building Unit',
        value: 'buildingUnit',
    },
    {
        id: '8',
        label: 'Order By',
        value: 'orderBy',
    },
]

export const invoicesSortByDefaultValues = [
    {
        id: '1',
        label: 'Invoice Number',
        value: 'invoiceNumber',
    },
    {
        id: '2',
        label: 'Invoice Date',
        value: 'invoiceDate',
    },
    {
        id: '3',
        label: 'Due Date',
        value: 'dueDate',
    },
    {
        id: '4',
        label: 'PO Number',
        value: 'poNumber',
    },
    {
        id: '5',
        label: 'Building',
        value: 'building',
    },
    {
        id: '6',
        label: 'Unit',
        value: 'Unit',
    },
    {
        id: '7',
        label: 'Job',
        value: 'Job',
    },
    {
        id: '8',
        label: 'Ordered By',
        value: 'Ordered By',
    },

    {
        id: '9',
        label: 'Amount',
        value: 'Amount',
    },

    {
        id: '10',
        label: 'Due',
        value: 'Due',
    },
]

export const searchFieldDefaultValues = [
    {
        id: '1',
        label: 'PO Number',
        value: 'poNumber',
    },
    {
        id: '2',
        label: 'Order Date',
        value: 'orderDate',
    },
    {
        id: '3',
        label: 'Install Date',
        value: 'installDate',
    },
    {
        id: '4',
        label: 'Customer Name',
        value: 'customerName',
    },
    {
        id: '5',
        label: 'Location Code',
        value: 'locationCode',
    },
    {
        id: '6',
        label: 'Order Number',
        value: 'orderNumber',
    },
    {
        id: '7',
        label: 'Building Unit',
        value: 'buildingUnit',
    },
]

export const invoiceTypeDefaultValues = [
    {
        id: '1',
        label: 'Invoice',
        value: 'invoice',
    },
    {
        id: '2',
        label: 'prorateinvoice',
        value: 'prorateinvoice',
    },
] as OrderSortOrSearchField[]

export const InvoiceStatusDefaultValues = [
    {
        id: '1',
        label: 'All',
        value: 'all',
    },
    {
        id: '2',
        label: 'Paid',
        value: 'paid',
    },
    {
        id: '3',
        label: 'Unpaid',
        value: 'unpaid',
    },
] as OrderSortOrSearchField[]

export const statusDefaultValues = [
    {
        id: '1',
        label: 'All',
        value: 'all',
    },
    {
        id: '2',
        label: 'Installed',
        value: 'installed',
    },
    {
        id: '3',
        label: 'Pending',
        value: 'pending',
    },
] as OrderSortOrSearchField[]
